// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, FormWidget } from "lib";

import { jsonToFormData } from "helperFn/formData";
import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { usePolicyUpdateMutation } from "store/apiSlice";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { usePolicyDetailsQuery } from "store/apiSlice";

import { useInsurerFetchQuery } from "store/apiSlice";

import { usePospFetchQuery } from "store/apiSlice";
function PolicyEdit() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [policyUpdateMutationTrigger, policyUpdateMutationResult] = usePolicyUpdateMutation();
    const history = useNavigate();
    ({ id: page.parameters.route.id } = useParams());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "Edit Policy" }));
    }, []);
    [page.parameters.coverageTypeData, page.parameters.coverageTypeDataSet] = useState("");
    [page.parameters.paymentModeData, page.parameters.paymentModeDataSet] = useState("");
    const policyDetailsParams = { id: page.parameters.route.id };
    ({
        data: page.data.policyDetailData,
        isFetching: page.data.policyDetailDataIsFetching,
        isLoading: page.data.policyDetailDataIsLoading,
    } = usePolicyDetailsQuery(policyDetailsParams));
    const insurerFetchParams = {};
    ({
        data: page.data.insurerAutocomplete,
        isFetching: page.data.insurerAutocompleteIsFetching,
        isLoading: page.data.insurerAutocompleteIsLoading,
    } = useInsurerFetchQuery(insurerFetchParams));
    const pospFetchParams = {};
    ({
        data: page.data.pospAutocomplete,
        isFetching: page.data.pospAutocompleteIsFetching,
        isLoading: page.data.pospAutocompleteIsLoading,
    } = usePospFetchQuery(pospFetchParams));

    const handleCoverageTypeChange = (data) => {
        toggleCoverageTypeData(data);
    };

    const handleProductTypeChange = (data) => {
        changeProductTypeData(data);
    };

    const leadNavigate = (data) => {
        history("/policy-list");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Policy Updated Successfully.", { variant: "success" });
    };

    const policyUpdate = async (data) => {
        var apiData = data;

        if (apiData) {
            policyUpdateMutationTrigger({ id: apiData["id"], data: jsonToFormData({ ...apiData }) })
                .unwrap()
                .then((data) => {
                    showSuccessAlert(data);
                    leadNavigate(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const policyUpdateApi = (data) => {
        policyUpdate(data);
    };

    const changeProductTypeData = (data) => {
        var parameterValue = data;

        page.parameters.productTypeDataSet(parameterValue);
    };

    const toggleCoverageTypeData = (data) => {
        var parameterValue = data;

        page.parameters.coverageTypeDataSet(parameterValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} xs={12}>
                <FormWidget
                    direction="column"
                    header="Edit Policy"
                    defaultValues={page.data.policyDetailData}
                    Onsubmit={policyUpdateApi}
                    fieldsets={[
                        {
                            direction: "row",
                            header: "Primary Details",
                            fields: [
                                {
                                    label: "Product Type",
                                    name: "product_type",
                                    type: "select",
                                    required: true,
                                    onChange: handleProductTypeChange,
                                    options: [
                                        { id: "TWO_WHEELER", label: "Two Wheeler" },
                                        { id: "FOUR_WHEELER", label: "Four Wheeler" },
                                        { id: "CV", label: "CV" },
                                    ],
                                },
                                {
                                    label: "POSP Code",
                                    name: "posp_id",
                                    type: "select",
                                    options: page.data.pospAutocomplete?.items || [],
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Customer Details",
                            fields: [
                                { label: "Customer Name", name: "customer_name", required: true, type: "text" },
                                { label: "Mobile No", name: "customer_phone", type: "text", required: true },
                                { label: "Email", name: "customer_email", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            fields: [
                                { label: "Address", name: "customer_address", required: true, md: 8, type: "text" },
                                { label: "Pincode", required: true, md: 4, name: "customer_pincode", type: "text" },
                            ],
                        },
                        { direction: "row", fields: [] },
                        {
                            direction: "row",
                            header: "Vehicle Details",
                            grid: 3,
                            fields: [
                                { label: "Make", required: true, name: "make", type: "text" },
                                { label: "Model", required: true, name: "model", type: "text" },
                                { label: "Variant", name: "variant", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                { label: "Chasis Number", name: "chasis_number", type: "text" },
                                { label: "Engine Number", name: "engine_number", type: "text" },
                                {
                                    label: "Manufacturing Year",
                                    md: 4,
                                    required: true,
                                    name: "manufacturing_year",
                                    type: "text",
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                {
                                    label: "Registration Number",
                                    name: "registration_number",
                                    md: 4,
                                    required: page.parameters.businessTypeData == "NEW",
                                    type: "text",
                                },
                                {
                                    label: "Registration Date",
                                    name: "registration_date",
                                    md: 4,
                                    required: page.parameters.businessTypeData == "NEW",
                                    type: "date",
                                },
                                { label: "RTO City", md: 4, name: "rto_city", type: "text" },
                                { label: "RTO State", md: 4, name: "rto_state", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Policy Details",
                            grid: 3,
                            fields: [
                                {
                                    label: "Insurer",
                                    name: "insurer_id",
                                    type: "select",
                                    required: true,
                                    options: page.data.insurerAutocomplete?.items || [],
                                },
                                {
                                    label: "Coverage Type",
                                    name: "coverage_type",
                                    type: "select",
                                    required: true,
                                    onChange: handleCoverageTypeChange,
                                    options: [
                                        { id: "SAOD", label: "SAOD" },
                                        { id: "LIABILITY", label: "LIABILITY" },
                                        { id: "PACKAGE", label: "PACKAGE" },
                                    ],
                                },
                                { label: "Policy Number", name: "policy_number", type: "text", required: true },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                { label: "Policy Issue Date", name: "issue_date", required: true, type: "date" },
                                { label: "Policy Start Date", name: "start_date", required: true, type: "date" },
                                { label: "Policy End Date", name: "end_date", required: true, type: "date" },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            header: "OD Date",
                            displayon:
                                page.parameters.coverageTypeData != ""
                                    ? page.parameters.coverageTypeData == "LIABILITY"
                                    : page.data.policyDetailData?.coverage_type == "LIABILITY",
                            fields: [
                                {
                                    label: "OD Start Date",
                                    name: "od_start_date",
                                    type: "date",
                                    displayon:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData == "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type == "LIABILITY",
                                    required:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData != "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type != "LIABILITY",
                                },
                                {
                                    label: "OD End Date",
                                    name: "od_end_date",
                                    type: "date",
                                    displayon:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData == "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type == "LIABILITY",
                                    required:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData != "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type != "LIABILITY",
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            header: "TP Date",
                            displayon:
                                page.parameters.coverageTypeData != ""
                                    ? page.parameters.coverageTypeData == "SAOD"
                                    : page.data.policyDetailData?.coverage_type == "SAOD",
                            fields: [
                                {
                                    label: "TP Start Date",
                                    name: "tp_start_date",
                                    type: "date",
                                    required: page.parameters.coverageTypeData != "SAOD",
                                    displayon:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData == "SAOD"
                                            : page.data.policyDetailData?.coverage_type == "SAOD",
                                },
                                {
                                    label: "TP End Date",
                                    name: "tp_end_date",
                                    type: "date",
                                    required: page.parameters.coverageTypeData != "SAOD",
                                    displayon:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData == "SAOD"
                                            : page.data.policyDetailData?.coverage_type == "SAOD",
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                { label: "Policy Document", name: "policy_document", type: "file", required: true },
                                {
                                    label: "Policy Status",
                                    name: "policy_status",
                                    type: "select",
                                    required: true,
                                    options: [
                                        { id: "PENDING", label: "Pending" },
                                        { id: "DONE", label: "Success" },
                                        { id: "CANCELLED", label: "Cancelled" },
                                    ],
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Previous Policy Details",
                            grid: 3,
                            fields: [
                                { label: "Previous Policy Number", name: "previous_policy_number", type: "text" },
                                { label: "Previous Insurer", name: "previous_insurer_name", type: "text" },
                                { label: "Previous Policy End Date", name: "previous_policy_end_date", type: "date" },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            displayon:
                                page.parameters.coverageTypeData != ""
                                    ? page.parameters.coverageTypeData == "LIABILITY"
                                    : page.data.policyDetailData?.coverage_type == "LIABILITY",
                            fields: [
                                {
                                    label: "Total IDV",
                                    name: "sum_insured",
                                    type: "text",
                                    prefix: "₹",
                                    md: 4,
                                    displayon:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData == "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type == "LIABILITY",
                                    required:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData == "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type == "LIABILITY",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "OD Premium Details",
                            grid: 3,
                            displayon:
                                page.parameters.coverageTypeData != ""
                                    ? page.parameters.coverageTypeData == "LIABILITY"
                                    : page.data.policyDetailData?.coverage_type == "LIABILITY",
                            fields: [
                                {
                                    label: "Basic OD Premium",
                                    name: "basic_od_premium",
                                    type: "text",
                                    prefix: "₹",
                                    displayon:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData == "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type == "LIABILITY",
                                    required:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData != "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type != "LIABILITY",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "NCB",
                                    name: "ncb",
                                    type: "select",
                                    options: [
                                        { id: "0", label: "0 %" },
                                        { id: "20", label: "20 %" },
                                        { id: "25", label: "25 %" },
                                        { id: "35", label: "35 %" },
                                        { id: "45", label: "45 %" },
                                        { id: "50", label: "50 %" },
                                    ],
                                    displayon:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData == "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type == "LIABILITY",
                                    required:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData != "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type != "LIABILITY",
                                },
                                {
                                    label: "Total OD Premium",
                                    name: "total_od_premium",
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                    displayon:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData == "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type == "LIABILITY",
                                    required:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData != "LIABILITY"
                                            : page.data.policyDetailData?.coverage_type != "LIABILITY",
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            header: "TP Premium Details",
                            displayon:
                                page.parameters.coverageTypeData != ""
                                    ? page.parameters.coverageTypeData == "SAOD"
                                    : page.data.policyDetailData?.coverage_type == "SAOD",
                            fields: [
                                {
                                    label: "Basic TP Premium",
                                    name: "basic_tp_premium",
                                    type: "text",
                                    prefix: "₹",
                                    displayon:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData == "SAOD"
                                            : page.data.policyDetailData?.coverage_type == "SAOD",
                                    required: page.parameters.coverageTypeData != "SAOD",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "Total TP Premium",
                                    name: "total_tp_premium",
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                    displayon:
                                        page.parameters.coverageTypeData != ""
                                            ? page.parameters.coverageTypeData == "SAOD"
                                            : page.data.policyDetailData?.coverage_type == "SAOD",
                                    required: page.parameters.coverageTypeData != "SAOD",
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Addons",
                            grid: 3,
                            fields: [
                                {
                                    label: "RSA Premium",
                                    name: "roadside_premium",
                                    md: 4,
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "Zero Dep Premium",
                                    name: "zero_dep_premium",
                                    md: 4,
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "Engine Cover Premium",
                                    name: "engine_cover_premium",
                                    md: 4,
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                {
                                    label: "PA Cover Premium",
                                    name: "pa_cover_premium",
                                    md: 4,
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "IMT 34 Premium",
                                    name: "imt_34_cover",
                                    md: 4,
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            header: "Total Premium Details",
                            fields: [
                                {
                                    label: "Net Premium",
                                    name: "net_premium",
                                    type: "text",
                                    required: true,
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "Taxes",
                                    name: "service_tax",
                                    type: "text",
                                    prefix: "₹",
                                    required: true,
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "Total Premium",
                                    name: "total_premium",
                                    type: "text",
                                    prefix: "₹",
                                    required: true,
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Payment Details",
                            grid: 3,
                            fields: [
                                {
                                    label: "Payment Mode",
                                    name: "payment_mode",
                                    type: "select",
                                    options: [
                                        { id: "ONLINE", label: "Online" },
                                        { id: "OFFLINE", label: "Offline" },
                                    ],
                                },
                                { label: "Payment Reference Number", name: "payment_reference_number", type: "text" },
                                {
                                    label: "Payment Method",
                                    name: "payment_method",
                                    type: "select",
                                    options: [
                                        { id: "CHEQUE", label: "Cheque" },
                                        { id: "DD", label: "DD" },
                                        { id: "UPI", label: "UPI" },
                                        { id: "NET_BANKING", label: "Net Banking" },
                                        { id: "CREDIT_CARD", label: "Credit Card" },
                                        { id: "DEBIT_CARD", label: "Debit Card" },
                                    ],
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                { label: "Additional Info", name: "additional_info", type: "text" },
                                {
                                    label: "Payment Document",
                                    name: "payment_document",
                                    required: page.parameters.paymentModeData == "OFFLINE",
                                    type: "file",
                                },
                                {
                                    label: "Payment Status",
                                    name: "payment_status",
                                    type: "select",
                                    options: [
                                        { id: "PENDING", label: "Pending" },
                                        { id: "SUCCESS", label: "Success" },
                                        { id: "FAILED", label: "Failed" },
                                    ],
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>
        </div>
    );
}
export default PolicyEdit;
