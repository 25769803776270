import Stack from "@mui/material/Stack";
import { format, parseISO } from 'date-fns';
import { PMGrid, PMText } from "../../pmcomponents";
import { BaseWidget, BaseWidgetProps } from "../BaseWidget";
import { Box } from "@mui/material";

export interface DetailWidgetProps {
    text?: string,
    textColor?: string,
    icon?: string
    data: Object
    fields: Array<any>
    columns?: number
    columnSpacing?: number
    rowSpacing?: number
}

const locale = 'en-IN'
const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'INR',
});

/* Example Field data*/
// const fields = [
//     {label: "First Name", key: "first_name" },
//     {label: "Last Name", key: "last_name"},
//     {label: "Phone", key: "phone"},
//     {label: "Email", key: "email"}
// ]

export const DetailWidget = (props: BaseWidgetProps & DetailWidgetProps) => {
    const {columns, columnSpacing, rowSpacing, ...baseProps} = props;

    return (
        <BaseWidget {...baseProps}>
                <Box sx={{
                        display: 'grid',
                        columnGap: columnSpacing,
                        rowGap: rowSpacing,
                        gridTemplateColumns: `repeat(${columns}, 1fr)`,
                    }}
                    >
                    {
                        props.fields.map((field, index) => {
                            return (
                                <PMGrid key={index}>
                                    <Stack>
                                        <PMText variant="caption">{field.label}</PMText>
                                        {field?.type==="img"?<> <a href={field.value} title="description">click here</a> </>:<></>}
                                        {field?.type==="currency"?(<PMText variant="body1">{currencyFormatter.format(field?.value||0)}</PMText>):""}
                                        {field?.type==="date"?(<PMText variant="body1">{field?.value?format(parseISO(field?.value), "dd-MMM-yyyy"):"--"}</PMText>):""}
                                        {field?.type==="datetime"?(<PMText variant="body1">{field?.value?format(parseISO(field?.value), "dd-MMM-yyyy h:m a"):"--"}</PMText>):""}
                                        {field?.type?"":<><PMText variant="body1">{field.value ? field.value : props.data ? props.data[field.key]: ""}</PMText>
                                        {(field?.subtext || (props?.data && props?.data[field?.subkey])) ? <PMText variant="body2">{field.subtext ? field.subtext : props?.data[field?.subkey]}</PMText>:<></>}</>}
                                        
                                    </Stack>
                                </PMGrid>)
                        })
                    }
                </Box>
        </BaseWidget>
    )
}

DetailWidget.defaultProps = {
    columns: 4,
    columnSpacing: 1,
    rowSpacing: 3,
}

export default DetailWidget