// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, DetailWidget, FormWidget, TableWidget } from "lib";

import { jsonToFormData } from "helperFn/formData";
import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useInteractionCreateMutation } from "store/apiSlice";
import { default as pospinteractionCreateFormatter } from "transformations/pospinteractionCreateFormatter";
import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { usePospDetailQuery } from "store/apiSlice";

import { usePospDetailStatsQuery } from "store/apiSlice";

import { usePolicyRenewalsListQuery } from "store/apiSlice";

import { useRenewalInteractionHistoryQuery } from "store/apiSlice";
function POSPRenewalDetails() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [interactionCreateMutationTrigger, interactionCreateMutationResult] = useInteractionCreateMutation();
    ({ id: page.parameters.route.id } = useParams());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "POSP Detail" }));
    }, []);
    [page.parameters.renewalListFilters, page.parameters.renewalListFiltersSet] = useState({});
    [page.parameters.renewalListPagination, page.parameters.renewalListPaginationSet] = useState({ size: 50 });
    [page.parameters.renewalInteractionHistoryPagination, page.parameters.renewalInteractionHistoryPaginationSet] =
        useState({ size: 6 });
    const pospDetailParams = { id: page.parameters.route.id };
    ({
        data: page.data.pospDetailData,
        isFetching: page.data.pospDetailDataIsFetching,
        isLoading: page.data.pospDetailDataIsLoading,
    } = usePospDetailQuery(pospDetailParams));
    const pospDetailStatsParams = {};
    ({
        data: page.data.pospDetailStatsData,
        isFetching: page.data.pospDetailStatsDataIsFetching,
        isLoading: page.data.pospDetailStatsDataIsLoading,
    } = usePospDetailStatsQuery(pospDetailStatsParams));
    const policyRenewalsListParams = {
        order_by: "-due_date",
        posp_id: page.parameters.route.id,
        ...page.parameters.renewalListFilters,
        ...page.parameters.renewalListPagination,
    };
    ({
        data: page.data.policyRenewalsListData,
        isFetching: page.data.policyRenewalsListDataIsFetching,
        isLoading: page.data.policyRenewalsListDataIsLoading,
    } = usePolicyRenewalsListQuery(policyRenewalsListParams));
    const renewalInteractionHistoryParams = {
        order_by: "-created_at",
        entity_id: page.parameters.route.id,
        ...page.parameters.renewalInteractionHistoryPagination,
    };
    ({
        data: page.data.renewalInteractionHistoryData,
        isFetching: page.data.renewalInteractionHistoryDataIsFetching,
        isLoading: page.data.renewalInteractionHistoryDataIsLoading,
    } = useRenewalInteractionHistoryQuery(renewalInteractionHistoryParams));

    const filterSubmit = (data) => {
        setRenewalListFilters(data);
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Interaction Created", { variant: "success" });
    };

    const interactionCreate = async (data) => {
        var apiData = pospinteractionCreateFormatter(data, page.data.pospDetailData);

        if (apiData) {
            interactionCreateMutationTrigger(jsonToFormData({ ...apiData }))
                .unwrap()
                .then((data) => {
                    showSuccessAlert(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const interactionSubmitApi = (data) => {
        interactionCreate(data);
    };

    const setRenewalListFilters = (data) => {
        var parameterValue = data;

        page.parameters.renewalListFiltersSet(parameterValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} md={12} direction="row" sx={{ my: 2 }}>
                <PMGrid container={true} md={6}>
                    <PMGrid item={true} container={true} direction="column" background="#FFFFFF">
                        <DetailWidget
                            direction="row"
                            header="POSP Details"
                            md={9}
                            fields={[
                                { label: "POSP Name", value: page.data.pospDetailData?.name || "" },
                                { label: "POSP Code", value: page.data.pospDetailData?.posp_code || "" },
                                { label: "RM Name", value: page.data.pospDetailData?.rm?.name || "" },
                            ]}
                        ></DetailWidget>
                    </PMGrid>

                    <PMGrid container={true}>
                        <FormWidget
                            sx={{ my: 2 }}
                            header="Create Interaction with POSP"
                            submitButtonLabel="Send"
                            Onsubmit={interactionSubmitApi}
                            fieldsets={[
                                {
                                    direction: "rows",
                                    grid: 12,
                                    fields: [
                                        { label: "Message", name: "message", type: "textarea", md: 12, required: true },
                                        {
                                            label: "Mode",
                                            name: "mode",
                                            type: "select",
                                            md: 12,
                                            required: true,
                                            options: [
                                                { id: "EMAIL", label: "Email" },
                                                { id: "WHATSAPP", label: "WhatsApp" },
                                                { id: "SMS", label: "SMS" },
                                            ],
                                        },
                                        { label: "Attach Document", name: "document", md: 12, type: "file" },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    </PMGrid>
                </PMGrid>

                <PMGrid container={true} md={6}>
                    <TableWidget
                        header="Interaction History"
                        showSerialNumber={false}
                        showQuickFilter={false}
                        xs={12}
                        tableApi={page.data.renewalInteractionHistoryData || []}
                        tableParams={[
                            page.parameters.renewalInteractionHistoryPagination,
                            page.parameters.renewalInteractionHistoryPaginationSet,
                        ]}
                        columns={[
                            { field: "created_at", headerName: "Created At", type: "datetime" },
                            { field: "type", headerName: "Mode" },
                            { field: "content", headerName: "Message" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </PMGrid>

            <PMGrid container={true} justifyContent="right">
                <PMGrid container={true} xs={6}>
                    <FormWidget
                        submitButtonLabel="Filter"
                        submitOnChange={true}
                        fullWidth="true"
                        header="Add Filter"
                        Onsubmit={filterSubmit}
                        fieldsets={[
                            {
                                direction: "column",
                                fields: [
                                    {
                                        label: "Select Status",
                                        name: "status",
                                        type: "select",
                                        md: "6",
                                        options: [
                                            { id: "", label: "--" },
                                            { id: "DONE", label: "Done" },
                                            { id: "PENDING", label: "Pending" },
                                            { id: "MISSED", label: "Missed" },
                                            { id: "FAILED", label: "Failed" },
                                        ],
                                        required: true,
                                    },
                                ],
                            },
                        ]}
                    ></FormWidget>
                </PMGrid>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    showSerialNumber={false}
                    showQuickFilter={false}
                    header="POSP Renewals LIst"
                    xs={12}
                    tableApi={page.data.policyRenewalsListData || [] || []}
                    tableParams={[page.parameters.renewalListPagination, page.parameters.renewalListPaginationSet]}
                    columns={[
                        { field: "due_date", headerName: "Due Date", type: "date", flex: 0.7 },
                        { field: "existing_policy_number", headerName: "Policy Number", flex: 1.2 },
                        { field: "product_type", headerName: "Product Type", flex: 1.2 },
                        { field: "insurer.name", headerName: "Insurer", flex: 1.7 },
                        { field: "existing_policy.customer_name", headerName: "Customer Name" },
                        { field: "existing_policy.total_premium", headerName: "Total Premium", type: "currency" },
                        { field: "insurer_renewal_notice", headerName: "Insurer RN", flex: 0.5, type: "download" },
                        { field: "broker_renewal_notice", headerName: "My RN", flex: 0.5, type: "download" },
                        {
                            field: "status",
                            headerName: "Renewal Status",
                            type: "chip",
                            condition: [
                                { rule: "MISSED", color: "error" },
                                { rule: "PENDING", color: "warning" },
                                { rule: "DONE", color: "success" },
                            ],
                            flex: 0.6,
                        },
                        { field: "View", headerName: "View", type: "edit", url: "/renewal", flex: 0.6, condition: [] },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default POSPRenewalDetails;
