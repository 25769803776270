// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, FormWidget } from "lib";

import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useKycApiMutation } from "store/apiSlice";
import { default as kycCreateFormatter } from "transformations/kycCreateFormatter";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";
function KYC() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [kycApiMutationTrigger, kycApiMutationResult] = useKycApiMutation();
    const history = useNavigate();
    ({ id: page.parameters.route.id } = useParams());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "Upload KYC" }));
    }, []);

    const afterKYC = (data) => {
        history("/kyc");
    };

    const kycApi = async (data) => {
        var apiData = kycCreateFormatter(data);

        if (apiData["kyc"] && apiData["kyc"].length) {
            apiData["kyc"].forEach(async (apiData) => {
                if (apiData) {
                    kycApiMutationTrigger({ ...apiData })
                        .unwrap()
                        .then((data) => {
                            afterKYC(data);
                        })
                        .catch((error) => {
                            console.log(error);
                            enqueueSnackbar(errorSerializer(error), { variant: "error" });
                        });
                }
            });
        }
    };

    const kycForm = (data) => {
        kycApi(data);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid direction="column" alignItems="center" justifyContent="center">
                <FormWidget
                    direction="column"
                    sx={{ marginTop: 4 }}
                    header="KYC Details"
                    Onsubmit={kycForm}
                    fieldsets={[
                        {
                            direction: "column",
                            fields: [
                                {
                                    label: "Enter Pan Number",
                                    name: "pan_number",
                                    type: "text",
                                    required: true,
                                    fullWidth: true,
                                },
                                {
                                    label: "Upload Pan Document",
                                    name: "pan_document",
                                    type: "file",
                                    required: true,
                                    fullWidth: true,
                                },
                                {
                                    label: "Enter Aadhar Number",
                                    name: "adhar_number",
                                    type: "text",
                                    required: true,
                                    fullWidth: true,
                                },
                                {
                                    label: "Upload Aadhar Document",
                                    name: "adhar_document",
                                    type: "file",
                                    required: true,
                                    fullWidth: true,
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>
        </div>
    );
}
export default KYC;
