// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, PMText, StatsCardWidget2, FormWidget, TableWidget } from "lib";

import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { usePospListAutocompleteQuery } from "store/apiSlice";

import { usePospListQuery } from "store/apiSlice";

import { usePospDirectRenewalQuery } from "store/apiSlice";

import { useRenewalStatsPospListQuery } from "store/apiSlice";
function POSPRenewalsList() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "POSP List" }));
    }, []);
    [page.parameters.renewalStatsPospListFilter, page.parameters.renewalStatsPospListFilterSet] = useState({});
    [page.parameters.renewalStatsPospListParams, page.parameters.renewalStatsPospListParamsSet] = useState({
        size: 50,
    });
    const pospListAutocompleteParams = {};
    ({
        data: page.data.pospListAutocompleteData,
        isFetching: page.data.pospListAutocompleteDataIsFetching,
        isLoading: page.data.pospListAutocompleteDataIsLoading,
    } = usePospListAutocompleteQuery(pospListAutocompleteParams));
    const pospListParams = {
        ...page.parameters.renewalStatsPospListFilter,
        ...page.parameters.renewalStatsPospListParams,
    };
    ({
        data: page.data.pospListData,
        isFetching: page.data.pospListDataIsFetching,
        isLoading: page.data.pospListDataIsLoading,
    } = usePospListQuery(pospListParams));
    const pospDirectRenewalParams = {};
    ({
        data: page.data.pospDirectRenewalData,
        isFetching: page.data.pospDirectRenewalDataIsFetching,
        isLoading: page.data.pospDirectRenewalDataIsLoading,
    } = usePospDirectRenewalQuery(pospDirectRenewalParams));
    const renewalStatsPospListParams = { metric: "status" };
    ({
        data: page.data.renewalStatsPospListData,
        isFetching: page.data.renewalStatsPospListDataIsFetching,
        isLoading: page.data.renewalStatsPospListDataIsLoading,
    } = useRenewalStatsPospListQuery(renewalStatsPospListParams));

    const filterChange = (data) => {
        setFilterParamters(data);
    };

    const setFilterParamters = (data) => {
        var parameterValue = data;

        page.parameters.renewalStatsPospListFilterSet(parameterValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid>
                <PMText children="POSP - Renewals" variant="h6"></PMText>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={
                        [
                            {
                                label: "Done",
                                value: (
                                    (page.data.renewalStatsPospListData?.DONE?.COUNT /
                                        page.data.renewalStatsPospListData?.TOTAL.COUNT) *
                                        100 || 0
                                ).toFixed(2),
                                type: "percentage",
                            },
                            { label: "Total Due", value: page.data.renewalStatsPospListData?.TOTAL?.COUNT || 0 },
                            { label: "Done", value: page.data.renewalStatsPospListData?.DONE?.COUNT || 0 },
                        ] || []
                    }
                    statsIcon="change_circle"
                    statsIconColor="primary"
                ></StatsCardWidget2>

                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={
                        [
                            {
                                label: "POSP Percentage",
                                value: page.data.pospDirectRenewalData?.posp_renewals_per || 0,
                                type: "percentage",
                            },
                            { label: "POSP Renewals", value: page.data.pospDirectRenewalData?.posp_renewals || 0 },
                            { label: "Direct Renewals", value: page.data.pospDirectRenewalData?.direct_renewals || 0 },
                        ] || []
                    }
                    statsIcon="change_circle"
                    statsIconColor="warning"
                ></StatsCardWidget2>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <FormWidget
                    header="Apply Filter"
                    submitButtonLabel="Search"
                    fullWidth="true"
                    Onsubmit={filterChange}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                {
                                    label: "Search by POSP Name, POSP Code",
                                    name: "posp_id",
                                    type: "select",
                                    md: "6",
                                    options: page.data.pospListAutocompleteData?.items || [],
                                },
                                {
                                    label: "Select Duration",
                                    name: "interval",
                                    type: "select",
                                    md: "6",
                                    options: [
                                        { id: "", label: "---" },
                                        { id: "next_seven_days", label: "Next Seven Days" },
                                        { id: "next_thirty_days", label: "Next Thirty Days" },
                                        { id: "next_sixty_days", label: "Next Sixty Days" },
                                        { id: "in_grace_period", label: "In Grace Period" },
                                    ],
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    showSerialNumber={false}
                    header="POSP List"
                    showQuickFilter={false}
                    xs={12}
                    tableApi={page.data.pospListData || [] || []}
                    tableParams={[
                        page.parameters.renewalStatsPospListParams,
                        page.parameters.renewalStatsPospListParamsSet,
                    ]}
                    columns={[
                        { field: "name", headerName: "POSP Name" },
                        { field: "posp_code", headerName: "POSP Code" },
                        { field: "rm.name", headerName: "RM" },
                        { field: "renewal_posp.length", headerName: "Total Renewals Due" },
                        { field: "renewals.DONE.COUNT", headerName: "Done" },
                        { field: "renewals.PENDING.COUNT", headerName: "Pending" },
                        { field: "renewals.MISSED.COUNT", headerName: "Missed" },
                        { field: "renewals.FAILED.COUNT", headerName: "Lost" },
                        {
                            field: "View",
                            headerName: "View",
                            type: "edit",
                            url: "/posp-renewals",
                            flex: 0.8,
                            condition: [],
                        },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default POSPRenewalsList;
