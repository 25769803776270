// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, TableWidget } from "lib";

import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { useBranchRenewalsDoneListQuery } from "store/apiSlice";
function BranchRenewalsList() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    ({ sales_branch: page.parameters.route.sales_branch } = useParams());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "RM Renewals" }));
    }, []);
    const branchRenewalsDoneListParams = { sales_branch: page.parameters.route.sales_branch };
    ({
        data: page.data.branchRenewalsDoneListData,
        isFetching: page.data.branchRenewalsDoneListDataIsFetching,
        isLoading: page.data.branchRenewalsDoneListDataIsLoading,
    } = useBranchRenewalsDoneListQuery(branchRenewalsDoneListParams));

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    showSerialNumber={false}
                    showQuickFilter={false}
                    header="RM wise Renewals Data"
                    hideFooter={true}
                    xs={12}
                    rows={page.data.branchRenewalsDoneListData || []}
                    columns={[
                        { field: "data.name", headerName: "RM Name" },
                        {
                            field: "data.code",
                            headerName: "RM Code",
                            type: "relatedlink",
                            url: "rm-renewals-list",
                            urlField: "id",
                        },
                        { field: "total.COUNT", headerName: "Total Renewals" },
                        { field: "total.SUM", headerName: "Total Premium", type: "currency" },
                        { field: "data.nested_data.PENDING.COUNT", headerName: "Pending" },
                        { field: "data.nested_data.DONE.COUNT", headerName: "Done" },
                        { field: "data.nested_data.MISSED.COUNT", headerName: "Missed" },
                        { field: "data.nested_data.FAILED.COUNT", headerName: "Lost" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default BranchRenewalsList;
