// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, DetailWidget, PMButton } from "lib";

import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useVerifyAdharMutation } from "store/apiSlice";
import { default as verifyAdharFormatter } from "transformations/verifyAdharFormatter";

import { useRejectAdharMutation } from "store/apiSlice";
import { default as rejectAdharFormatter } from "transformations/rejectAdharFormatter";

import { useVerifyPanMutation } from "store/apiSlice";
import { default as verifyPanFormatter } from "transformations/verifyPanFormatter";

import { useRejectPanMutation } from "store/apiSlice";
import { default as rejectPanFormatter } from "transformations/rejectPanFormatter";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { usePospKycListQuery } from "store/apiSlice";
function VerifyKYC() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [verifyAdharMutationTrigger, verifyAdharMutationResult] = useVerifyAdharMutation();
    const [rejectAdharMutationTrigger, rejectAdharMutationResult] = useRejectAdharMutation();
    const [verifyPanMutationTrigger, verifyPanMutationResult] = useVerifyPanMutation();
    const [rejectPanMutationTrigger, rejectPanMutationResult] = useRejectPanMutation();
    const history = useNavigate();
    const pospKycListData = useSelector((states) => states?.appStore?.pospKycListData);
    ({ id: page.parameters.route.id } = useParams());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "KYC Verification" }));
    }, []);
    const pospKycListParams = { posp_id: "{{page.parameters.route.id}}" };
    ({
        data: page.data.pospKycListData,
        isFetching: page.data.pospKycListDataIsFetching,
        isLoading: page.data.pospKycListDataIsLoading,
    } = usePospKycListQuery(pospKycListParams));

    const leadNavigate = (data) => {
        history("/kyc");
    };

    const rejectPan = async (data) => {
        var apiData = rejectPanFormatter(data);

        if (apiData) {
            rejectPanMutationTrigger({ id: apiData["id"], data: { ...apiData } })
                .unwrap()
                .then((data) => {
                    leadNavigate(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const rejectPanApi = (data) => {
        rejectPan(data);
    };

    const verifyPan = async (data) => {
        var apiData = verifyPanFormatter(data);

        if (apiData) {
            verifyPanMutationTrigger({ id: apiData["id"], data: { ...apiData } })
                .unwrap()
                .then((data) => {
                    leadNavigate(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const verifyPanApi = (data) => {
        verifyPan(data);
    };

    const rejectAdhar = async (data) => {
        var apiData = rejectAdharFormatter(data);

        if (apiData) {
            rejectAdharMutationTrigger({ id: apiData["id"], data: { ...apiData } })
                .unwrap()
                .then((data) => {
                    leadNavigate(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const rejectAdharApi = (data) => {
        rejectAdhar(data);
    };

    const verifyAdhar = async (data) => {
        var apiData = verifyAdharFormatter(data);

        if (apiData) {
            verifyAdharMutationTrigger({ id: apiData["id"], data: { ...apiData } })
                .unwrap()
                .then((data) => {
                    leadNavigate(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const verifyAdharApi = (data) => {
        verifyAdhar(data);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid direction="column" alignItems="center" justifyContent="center">
                <DetailWidget
                    header="KYC Documents"
                    direction="row"
                    sx={{ marginTop: 4 }}
                    fields={[
                        { label: "Aadhar Number", value: pospKycListData?.aadhar },
                        { label: "Aadhar Document", value: pospKycListData?.aadhar_document },
                    ]}
                ></DetailWidget>

                <PMGrid direction="row" sx={{ marginTop: 4 }}>
                    <PMButton
                        label="Approve Aadhar"
                        variant="contained"
                        color="success"
                        sx={{ mx: 2 }}
                        onClick={verifyAdharApi}
                    ></PMButton>

                    <PMButton
                        label="Reject Aadhar"
                        variant="contained"
                        color="success"
                        sx={{ mx: 2 }}
                        onClick={rejectAdharApi}
                    ></PMButton>
                </PMGrid>
            </PMGrid>

            <DetailWidget
                header="KYC Documents"
                direction="row"
                sx={{ marginTop: 4 }}
                fields={[
                    { label: "Pan Number", value: pospKycListData?.pan },
                    { label: "PAN Document", value: pospKycListData?.pan_document },
                ]}
            ></DetailWidget>

            <PMGrid direction="row" sx={{ marginTop: 4 }}>
                <PMButton
                    variant="contained"
                    color="success"
                    sx={{ mx: 2 }}
                    label="Approve Pan"
                    onClick={verifyPanApi}
                ></PMButton>

                <PMButton
                    label="Reject Pan"
                    variant="contained"
                    color="success"
                    sx={{ mx: 2 }}
                    onClick={rejectPanApi}
                ></PMButton>
            </PMGrid>
        </div>
    );
}
export default VerifyKYC;
