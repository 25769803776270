// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, DetailWidget, DetailCheckedWidget, FormWidget, NotesWidget, TableWidget } from "lib";

import { jsonToFormData } from "helperFn/formData";
import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useMarkAsFailedMutation } from "store/apiSlice";

import { useInteractionCreateMutation } from "store/apiSlice";
import { default as interactionCreateFormatter } from "transformations/interactionCreateFormatter";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { useRenewalDetailsQuery } from "store/apiSlice";

import { useRenewalInteractionHistoryQuery } from "store/apiSlice";
function RenewalDetails() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [markAsFailedMutationTrigger, markAsFailedMutationResult] = useMarkAsFailedMutation();
    const [interactionCreateMutationTrigger, interactionCreateMutationResult] = useInteractionCreateMutation();
    const history = useNavigate();
    ({ id: page.parameters.route.id } = useParams());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "" }));
    }, []);
    [page.parameters.renewalStatusData, page.parameters.renewalStatusDataSet] = useState("");
    const renewalDetailsParams = { id: page.parameters.route.id };
    ({
        data: page.data.renewalDetailsData,
        isFetching: page.data.renewalDetailsDataIsFetching,
        isLoading: page.data.renewalDetailsDataIsLoading,
    } = useRenewalDetailsQuery(renewalDetailsParams));
    const renewalInteractionHistoryParams = {
        order_by: "-created_at",
        entity_type: "RENEWAL",
        entity_id: page.parameters.route.id,
    };
    ({
        data: page.data.renewalInteractionHistoryData,
        isFetching: page.data.renewalInteractionHistoryDataIsFetching,
        isLoading: page.data.renewalInteractionHistoryDataIsLoading,
    } = useRenewalInteractionHistoryQuery(renewalInteractionHistoryParams));

    const interactionCreateSuccessAlert = (data) => {
        enqueueSnackbar("Interaction Made Successfully.", { variant: "success" });
    };

    const interactionCreate = async (data) => {
        var apiData = interactionCreateFormatter(data, page.data.renewalDetailsData);

        if (apiData) {
            interactionCreateMutationTrigger(jsonToFormData({ ...apiData }))
                .unwrap()
                .then((data) => {
                    interactionCreateSuccessAlert(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const InteractionCreateApi = (data) => {
        interactionCreate(data);
    };

    const handleRenewalStatusChange = (data) => {
        changeRenewalStatusData(data);
    };

    const renewalNavigate = (data) => {
        history("/policy-renewals-all");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Renewals Status Updated Successfully.", { variant: "success" });
    };

    const markAsFailed = async (data) => {
        var apiData = data;

        if (apiData) {
            markAsFailedMutationTrigger({ id: apiData["id"], data: jsonToFormData({ ...apiData }) })
                .unwrap()
                .then((data) => {
                    showSuccessAlert(data);
                    renewalNavigate(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const updateStatusRenewal = (data) => {
        markAsFailed(data);
    };

    const changeRenewalStatusData = (data) => {
        var parameterValue = data;

        page.parameters.renewalStatusDataSet(parameterValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid item={true} container={true} direction="column" background="#FFFFFF">
                <PMGrid container={true} direction="row" md="12">
                    <DetailWidget
                        direction="row"
                        header="Existing Policy"
                        md={6}
                        fields={[
                            {
                                label: "Existing Policy Number",
                                value: page.data.renewalDetailsData?.existing_policy_number,
                            },
                            { label: "Renewal Status", value: page.data.renewalDetailsData?.status },
                        ]}
                    ></DetailWidget>
                    {"PAGE_VIEW_PolicyRenewalsMissed,PAGE_VIEW_PolicyRenewalsMissed"
                        .split(",")
                        .some((r) => localStorage.getItem("permissionCodes").split(",")?.indexOf(r) > 0) ? (
                        <DetailWidget
                            direction="row"
                            header="Sales Team Details"
                            md={6}
                            fields={[
                                { label: "POSP Name", value: page.data.renewalDetailsData?.posp?.name },
                                { label: "POSP Code", value: page.data.renewalDetailsData?.posp?.posp_code },
                            ]}
                        ></DetailWidget>
                    ) : (
                        ""
                    )}
                </PMGrid>

                <DetailWidget
                    direction="row"
                    header="Policy Details"
                    md={6}
                    fields={[
                        { label: "Product Type", value: page.data.renewalDetailsData?.product_type },
                        { label: "Coverage Type", value: page.data.renewalDetailsData?.coverage_type },
                        { label: "Insurer", value: page.data.renewalDetailsData?.insurer?.name },
                        {
                            label: "Sum Insured/IDV",
                            value: page.data.renewalDetailsData?.existing_policy.sum_insured,
                            type: "currency",
                        },
                        { label: "Renewal Due Date", value: page.data.renewalDetailsData?.due_date, type: "date" },
                    ]}
                ></DetailWidget>

                <PMGrid container={true} direction="row" md="12">
                    <DetailWidget
                        direction="column"
                        header="Customer Details"
                        md={6}
                        fields={[
                            {
                                label: "Customer Name",
                                value: page.data.renewalDetailsData?.existing_policy?.customer_name,
                            },
                            {
                                label: "Mobile No",
                                value: page.data.renewalDetailsData?.existing_policy?.customer_phone,
                            },
                            { label: "Email", value: page.data.renewalDetailsData?.existing_policy?.customer_email },
                            {
                                label: "Address",
                                value: page.data.renewalDetailsData?.existing_policy?.customer_address,
                            },
                            {
                                label: "Pincode",
                                value: page.data.renewalDetailsData?.existing_policy?.customer_pincode,
                            },
                        ]}
                    ></DetailWidget>

                    <DetailWidget
                        direction="row"
                        header="Vehicle Details"
                        columns={4}
                        md={6}
                        fields={[
                            {
                                label: "Registration Number",
                                value: page.data.renewalDetailsData?.existing_policy?.registration_number,
                            },
                            { label: "Make", value: page.data.renewalDetailsData?.make },
                            { label: "Model", value: page.data.renewalDetailsData?.model },
                            { label: "Variant", value: page.data.renewalDetailsData?.existing_policy?.variant },
                            {
                                label: "Manufacturing Year",
                                value: page.data.renewalDetailsData?.existing_policy?.manufacturing_year,
                            },
                            {
                                label: "Registration Date",
                                value: page.data.renewalDetailsData?.existing_policy?.registration_date,
                            },
                            { label: "RTO City", value: page.data.renewalDetailsData?.existing_policy?.rto_city },
                            { label: "RTO State", value: page.data.renewalDetailsData?.existing_policy?.rto_state },
                            { label: "RTO Code", value: page.data.renewalDetailsData?.existing_policy?.rto_code },
                            { label: "Engine No", value: page.data.renewalDetailsData?.existing_policy?.engine_number },
                            {
                                label: "Chassis No",
                                value: page.data.renewalDetailsData?.existing_policy?.chasis_number,
                            },
                        ]}
                    ></DetailWidget>
                </PMGrid>

                <DetailWidget
                    direction="row"
                    header="Premium Details (Existing Insurer)"
                    md={6}
                    fields={[
                        {
                            label: "OD Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.basic_od_premium,
                        },
                        { label: "NCB", value: (page.data.renewalDetailsData?.ncb || 0) + "%" },
                        {
                            label: "Total OD Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.total_od_premium,
                        },
                        {
                            label: "Liability Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.tp_premium,
                        },
                        {
                            label: "Total Liability Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.total_tp_premium,
                        },
                    ]}
                ></DetailWidget>

                <DetailWidget
                    direction="row"
                    header="Addons Details (Existing Insurer)"
                    md={6}
                    fields={[
                        {
                            label: "Zero Cover Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.zero_dep_premium,
                        },
                        {
                            label: "PA Cover Premium",
                            value: page.data.renewalDetailsData?.existing_policy?.pa_cover_premium,
                            type: "currency",
                        },
                        {
                            label: "RSA Cover Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.roadside_premium,
                        },
                        {
                            label: "Engine Cover Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.engine_cover_premium,
                        },
                        {
                            label: "IMT 34 Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.imt_34_cover,
                        },
                    ]}
                ></DetailWidget>

                <DetailWidget
                    direction="row"
                    header="Total Premium Details (Existing Insurer)"
                    md={6}
                    fields={[
                        {
                            label: "Net Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.net_premium,
                        },
                        {
                            label: "GST",
                            value: page.data.renewalDetailsData?.existing_policy?.service_tax,
                            type: "currency",
                        },
                        {
                            label: "Total Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.total_premium,
                        },
                    ]}
                ></DetailWidget>

                <PMGrid container={true} direction="row" justifyContent="space-around">
                    <PMGrid
                        width={
                            page.data.renewalDetailsData?.status != "DONE" &&
                            page.data.renewalDetailsData?.status != "FAILED"
                                ? "50%"
                                : "100%"
                        }
                    >
                        <DetailCheckedWidget
                            direction="column"
                            header="Automated Reminders"
                            fields={[
                                {
                                    label: "1st Reminder: (T-60 Days)",
                                    data: [
                                        { name: "Customer", icon: "check_box" },
                                        { name: "POSP", icon: "check_box" },
                                    ],
                                },
                                {
                                    label: "2nd Reminder: (T-30 Days)",
                                    data: [
                                        { name: "Customer", icon: "check_box_outline_blank_icon" },
                                        { name: "POSP", icon: "check_box_outline_blank_icon" },
                                    ],
                                },
                                {
                                    label: "3rd Reminder: (T-7 Days)",
                                    data: [
                                        { name: "Customer", icon: "check_box_outline_blank_icon" },
                                        { name: "POSP", icon: "check_box_outline_blank_icon" },
                                        { name: "RM", icon: "check_box_outline_blank_icon" },
                                    ],
                                },
                            ]}
                        ></DetailCheckedWidget>
                    </PMGrid>
                    {page.data.renewalDetailsData?.status != "DONE" &&
                    page.data.renewalDetailsData?.status != "FAILED" ? (
                        <PMGrid width="50%">
                            <FormWidget
                                header="Update Renewal Status"
                                submitButtonLabel="Submit"
                                Onsubmit={updateStatusRenewal}
                                fieldsets={[
                                    {
                                        direction: "row",
                                        md: 12,
                                        fields: [
                                            {
                                                label: "Status",
                                                name: "status",
                                                type: "select",
                                                required: true,
                                                onChange: handleRenewalStatusChange,
                                                options: [
                                                    { id: "DONE", label: "Marks as Done" },
                                                    { id: "FAILED", label: "Marks as Failed" },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        direction: "row",
                                        md: 12,
                                        fields: [
                                            {
                                                label: "Remark",
                                                name: "failed_remark",
                                                type: "select",
                                                displayon: page.parameters.renewalStatusData != "FAILED",
                                                required: page.parameters.renewalStatusData == "FAILED",
                                                options: [
                                                    {
                                                        id: "Custmer Not Reachable",
                                                        label: "Customer Phone is Unavailable",
                                                    },
                                                    {
                                                        id: "Policy already issued with Another Intermediary",
                                                        label: "Customer Already purchased the Policy",
                                                    },
                                                ],
                                            },
                                            {
                                                label: "New Policy Number",
                                                name: "new_policy_number",
                                                type: "text",
                                                required: page.parameters.renewalStatusData == "DONE",
                                                displayon: page.parameters.renewalStatusData != "DONE",
                                            },
                                        ],
                                    },
                                ]}
                            ></FormWidget>

                            <NotesWidget
                                sx={{ marginTop: 2 }}
                                header="Note:"
                                content={[
                                    "The following system is a demo environment. When you select 'Mark as done' only renewal's status is changed to DONE. It is important to note that this action does not create a new policy. ",
                                ]}
                            ></NotesWidget>
                        </PMGrid>
                    ) : (
                        ""
                    )}
                </PMGrid>
            </PMGrid>

            <PMGrid container={true} direction="row" md="12" sx={{ my: 1 }} background="#FFFFFF">
                <PMGrid container={true} direction="row" md="6" background="#FFFFFF">
                    <FormWidget
                        header="Interact with Customer"
                        Onsubmit={InteractionCreateApi}
                        fieldsets={[
                            {
                                direction: "row",
                                grid: 6,
                                fields: [
                                    { label: "Message", name: "message", type: "textarea", required: true },
                                    { label: "Attach Document", name: "document", type: "file" },
                                    {
                                        label: "Mode",
                                        name: "mode",
                                        type: "select",
                                        required: true,
                                        options: [
                                            { id: "EMAIL", label: "Email" },
                                            { id: "WHATSAPP", label: "WhatsApp" },
                                            { id: "SMS", label: "SMS" },
                                        ],
                                    },
                                ],
                            },
                        ]}
                    ></FormWidget>
                </PMGrid>

                <PMGrid container={true} direction="row" md="6" background="#FFFFFF">
                    <TableWidget
                        header="Interaction History"
                        showSerialNumber={false}
                        showQuickFilter={false}
                        pageSize={5}
                        xs={12}
                        rows={page.data.renewalInteractionHistoryData?.items}
                        columns={[
                            { field: "created_at", headerName: "Date", type: "datetime" },
                            { field: "type", headerName: "Mode" },
                            { field: "content", headerName: "Message" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </PMGrid>
        </div>
    );
}
export default RenewalDetails;
