import TextField, { TextFieldProps } from "@mui/material/TextField";
import React from "react";
import "./PMFormatterText.scss";
import { PropsWithChildren } from "react";
import { PMText } from "..";
import moment from "moment";


import Typography, { TypographyProps } from "@mui/material/Typography";
import { Variant } from "@mui/material/styles/createTypography";

export interface PMFormatterTextProps extends TypographyProps {
    value?: any,
    formatType?: string,
    variant2?: string,

}
const locale = 'en-IN'
const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'INR',
});


const PMFormatterText = (props: PropsWithChildren<PMFormatterTextProps>) => {
    const { formatType, value, variant2 } = props

    return (

        <>

            {
                formatType === 'percentage' ?
                    <PMText
                        variant={variant2 as Variant} >
                        {value + '%'}
                    </PMText> :

                    formatType === 'currency' ?
                        <PMText
                            variant={variant2 as Variant} >
                            {currencyFormatter.format(value)}
                        </PMText> :

                        formatType === 'date' ?
                            <PMText
                                variant={variant2 as Variant} >
                                {moment(value).format('DD-MMM-YYYY')}
                            </PMText> :

                            <PMText variant={variant2 as Variant}>
                                {value}
                            </PMText>

            }
        </>
    )
};

export default PMFormatterText;