// @ts-nocheck
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import jwt_decode from "jwt-decode";
import { ApiLoaderWidget } from "lib";
import { SnackbarProvider } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { mergeStore } from "store/appSlice";
import AuthRoute from "./authComponent/AuthRoute";
import {
    BranchRenewalsList,
    Employee,
    EmployeeCreate,
    EmployeeUpdate,
    FollowupDetail,
    ForgotPassword,
    Help,
    HelpDesk,
    KYC,
    Leads,
    Login,
    MotorRulesMaster,
    MyActivityFollowup,
    NavigationMenu,
    POSPAdmin,
    POSPCodeRenewalDetails,
    POSPKyc,
    POSPRenewalDetails,
    POSPRenewalsDashboard,
    POSPRenewalsList,
    POSPTraining,
    PageNotFound,
    PayoutAdmin,
    PermissionDenied,
    PolicyCreate,
    PolicyDashboard,
    PolicyEdit,
    PolicyList,
    PolicyRenewalsAll,
    PolicyRenewalsDashboard,
    PolicyRenewalsDone,
    PolicyRenewalsMissed,
    PolicyRenewalsPending,
    RMRenewalsList,
    RenewalDetails,
    Reports,
    ResetPassword,
    Signup,
    UploadPdf,
    VerifyKYC,
    ZoneRenewalsList,
} from "./pages";
import { theme } from "./theme";
function App() {
    const dispatch = useDispatch();
    const auth_token = localStorage.getItem("authToken");
    const userProfile = localStorage.getItem("userProfile");
    const authTokenstore = useSelector((states) => states?.appStore?.authToken);
    const userProfileStore = useSelector((states) => states?.appStore?.userProfile);
    localStorage.setItem("userProfile", JSON.stringify(userProfileStore || {}));
    if (userProfileStore && !userProfile) {
        console.log(userProfileStore, userProfile, "in pm menu");
        localStorage.setItem("userProfile", JSON.stringify(userProfileStore || {}));
        // localStorage.setItem("permissionCodes", jwt_decode(authToken).permission_codes || "");
    } else if (!userProfileStore && userProfile) {
        console.log(userProfileStore, userProfile, "in pm menu");
        var formatedData = {
            userProfile: JSON.stringify(userProfile || {}),
            // permissionCodes: jwt_decode(auth_token).permission_codes || "",
        };
        dispatch(mergeStore(formatedData));
    }
    if (authTokenstore && !auth_token) {
        localStorage.setItem("authToken", authTokenstore);
    } else if (!authTokenstore && auth_token) {
        var formatedData = {
            authToken: auth_token,
        };
        dispatch(mergeStore(formatedData));
    }
    const authToken = authTokenstore ? authTokenstore : auth_token;
    if (authToken) {
        var decoded = jwt_decode(authToken);
        if (Date.now() > decoded.exp * 1000) {
            localStorage.clear();
        }
    } else {
        localStorage.clear();
    }

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ApiLoaderWidget></ApiLoaderWidget>
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                    <Routes>
                        <Route path="/login" element={<Login />}></Route>

                        <Route path="/forgot-password" element={<ForgotPassword />}></Route>

                        <Route path="/signup" element={<Signup />}></Route>

                        <Route path="/reset-password/:id" element={<ResetPassword />}></Route>

                        <Route path="/permission-denied" element={<PermissionDenied />}></Route>

                        <Route path="*" element={<PageNotFound />}></Route>

                        <Route element={<NavigationMenu />}>
                            <Route
                                path="/kyc/:id"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <KYC />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/kyc"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <POSPKyc />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/kyc-verification/:id"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <VerifyKYC />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/policy-renewals-dashboard"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyRenewalsDashboard />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/policy-renewals-pending"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyRenewalsPending />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/policy-renewals-missed"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyRenewalsMissed />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/policy-renewals-done"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyRenewalsDone />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/policy-renewals-all"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyRenewalsAll />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/renewal/:id"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <RenewalDetails />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/posp-renewals-dashboard"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <POSPRenewalsDashboard />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/branch-renewals-list/:sales_branch"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <BranchRenewalsList />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/zone-renewals-list/:sales_zone"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <ZoneRenewalsList />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/rm-renewals-list/:id"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <RMRenewalsList />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/posp-renewals-list"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <POSPRenewalsList />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/posp-code-renewals/:posp_code"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <POSPCodeRenewalDetails />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/posp-renewals/:id"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <POSPRenewalDetails />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/employee-create"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <EmployeeCreate />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/employee/:id"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <EmployeeUpdate />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/employee-list"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <Employee />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/my-followup"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <MyActivityFollowup />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/my-followup/:id"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <FollowupDetail />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/policy-create"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyCreate />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/upload-pdf"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <UploadPdf />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/helpdesk"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <HelpDesk />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/reports"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <Reports />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/posp-training"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <POSPTraining />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/posp-admin"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <POSPAdmin />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/payout-admin"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PayoutAdmin />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/payin/motor-rules-master"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <MotorRulesMaster />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/policy-edit/:id"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyEdit />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/leads"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <Leads />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/policy-list"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyList />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <PolicyDashboard />
                                    </AuthRoute>
                                }
                            ></Route>

                            <Route
                                path="/help"
                                element={
                                    <AuthRoute authToken={authToken}>
                                        <Help />
                                    </AuthRoute>
                                }
                            ></Route>
                        </Route>
                    </Routes>
                </SnackbarProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
