// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, TableWidget } from "lib";

import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { useEmployeeListQuery } from "store/apiSlice";
function Employee() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "Employee List" }));
    }, []);
    const employeeListParams = {};
    ({
        data: page.data.employeeListData,
        isFetching: page.data.employeeListDataIsFetching,
        isLoading: page.data.employeeListDataIsLoading,
    } = useEmployeeListQuery(employeeListParams));

    return (
        <div className="builder_wrapper">
            <>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Employee List"
                        showSerialNumber={true}
                        xs={12}
                        tableApi={page.data.employeeListData || []}
                        tableParams={[page.parameters.policyListPagination, page.parameters.policyListPaginationSet]}
                        columns={[
                            { field: "name", headerName: "Employee name" },
                            { field: "phone", headerName: "Phone" },
                            { field: "email", headerName: "Email" },
                            { field: "created_at", headerName: "Joining Date" },
                            { field: "role", headerName: "Role" },
                            { field: "department", headerName: "Department" },
                            { field: "reporting_manager.name", headerName: "Reporting Manager" },
                            { field: "status", headerName: "Active", type: "check" },
                            { headerName: "Edit", type: "edit", url: "employee" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </>
        </div>
    );
}
export default Employee;
