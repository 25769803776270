// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, StatsCardWidget2, FormWidget, TableWidget } from "lib";

import { default as durationFormatter } from "transformations/durationFormatter";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { usePolicyRenewalsListQuery } from "store/apiSlice";

import { usePolicyAllStatsCardQuery } from "store/apiSlice";
function PolicyRenewalsAll() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "All Renewals" }));
    }, []);
    [page.parameters.renewalListFilters, page.parameters.renewalListFiltersSet] = useState({});
    [page.parameters.renewalListPagination, page.parameters.renewalListPaginationSet] = useState({ size: 50 });
    const policyRenewalsListParams = {
        order_by: "-due_date",
        ...page.parameters.renewalListFilters,
        ...page.parameters.renewalListPagination,
    };
    ({
        data: page.data.policyRenewalsListData,
        isFetching: page.data.policyRenewalsListDataIsFetching,
        isLoading: page.data.policyRenewalsListDataIsLoading,
    } = usePolicyRenewalsListQuery(policyRenewalsListParams));
    const policyAllStatsCardParams = {};
    ({
        data: page.data.policyAllStatsCardData,
        isFetching: page.data.policyAllStatsCardDataIsFetching,
        isLoading: page.data.policyAllStatsCardDataIsLoading,
    } = usePolicyAllStatsCardQuery(policyAllStatsCardParams));

    const filterChange = (data) => {
        setRenewalListFilters(data);
    };

    const setRenewalListFilters = (data) => {
        var parameterValue = data;
        parameterValue = durationFormatter(parameterValue);
        page.parameters.renewalListFiltersSet(parameterValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid container={true}>
                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={[
                        {
                            label: "Change",
                            value: page.data.policyAllStatsCardData?.renewal_change,
                            type: "percentage",
                        },
                        {
                            label: "Renewal Done (MTD)",
                            value: page.data.policyAllStatsCardData?.renewal_this_month,
                            type: "percentage",
                        },
                        {
                            label: "Renewal Done (Last Month)",
                            value: page.data.policyAllStatsCardData?.renewal_last_month,
                            type: "percentage",
                        },
                    ]}
                    arrow={true}
                    statsIcon="change_circle"
                    statsIconColor="primary"
                ></StatsCardWidget2>

                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={[
                        { label: "Total Renewals Due", value: page.data.policyAllStatsCardData?.total_due_renewal },
                        { label: "Pending", value: page.data.policyAllStatsCardData?.total_pending_due_renewal },
                        { label: "Done", value: page.data.policyAllStatsCardData?.total_done_due_renewal },
                    ]}
                    statsIcon="timelapse"
                    statsIconColor="success"
                ></StatsCardWidget2>

                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={[
                        {
                            label: "Total Premium Due",
                            value: page.data.policyAllStatsCardData?.total_premium,
                            type: "currency",
                        },
                        {
                            label: "Premium Pending",
                            value: page.data.policyAllStatsCardData?.total_premium_pending,
                            type: "currency",
                        },
                        {
                            label: "Premium Done",
                            value: page.data.policyAllStatsCardData?.total_premium_done,
                            type: "currency",
                        },
                    ]}
                    statsIcon="track_changes"
                    statsIconColor="warning"
                ></StatsCardWidget2>

                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={[
                        { label: "Total Renewals Due", value: page.data.policyAllStatsCardData?.total_renewal },
                        { label: "Missed", value: page.data.policyAllStatsCardData?.total_missed_renewal },
                        { label: "Lost", value: page.data.policyAllStatsCardData?.total_lost_renewal },
                    ]}
                    statsIcon="event_available"
                    statsIconColor="error"
                ></StatsCardWidget2>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <FormWidget
                    submitButtonLabel="Search"
                    header="Apply Filter"
                    fullWidth="true"
                    Onsubmit={filterChange}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                {
                                    label: "Customer Name",
                                    name: "existing_policy__customer_name___search",
                                    type: "text",
                                    required: false,
                                },
                                { label: "Policy No", name: "existing_policy_number", type: "text", required: false },
                                {
                                    label: "Product Type",
                                    name: "product_type",
                                    type: "select",
                                    options: [
                                        { id: "", label: "---" },
                                        { id: "TWO_WHEELER", label: "Two Wheeler" },
                                        { id: "FOUR_WHEELER", label: "Four Wheeler" },
                                        { id: "CV", label: "Commercial Vehicle" },
                                    ],
                                    required: false,
                                },
                                {
                                    label: "Start Date",
                                    name: "status",
                                    type: "select",
                                    options: [
                                        { id: "", label: "---" },
                                        { id: "DONE", label: "Done" },
                                        { id: "PENDING", label: "Pending" },
                                        { id: "MISSED", label: "Missed" },
                                        { id: "FAILED", label: "Failed" },
                                    ],
                                    required: false,
                                },
                                {
                                    label: "Status",
                                    name: "due_date",
                                    type: "select",
                                    splitIntoDuration: true,
                                    options: [
                                        { id: "", label: "---" },
                                        { id: "this_month", label: "This Month" },
                                        { id: "last_month", label: "Last Month" },
                                        { id: "next_month", label: "Next Month" },
                                        { id: "last_7_days", label: "Last Seven Days" },
                                        { id: "next_7_days", label: "Next Seven Days" },
                                    ],
                                    required: false,
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    header="Renewals List"
                    xs={12}
                    tableApi={page.data.policyRenewalsListData || []}
                    tableParams={[page.parameters.renewalListPagination, page.parameters.renewalListPaginationSet]}
                    columns={[
                        { field: "renewal_id", headerName: "Renewal ID" },
                        { field: "due_date", headerName: "Due Date", type: "date" },
                        { field: "existing_policy_number", headerName: "Policy No." },
                        { field: "product_type", headerName: "Product Type", sortable: false },
                        { field: "insurer.name", headerName: "Insurer", sortable: false },
                        { field: "existing_policy.customer_name", headerName: "Customer Name" },
                        { field: "existing_policy.total_premium", headerName: "Total Premium", type: "currency" },
                        {
                            field: "insurer_renewal_notice",
                            headerName: "Insurer RN",
                            sortable: false,
                            type: "download",
                        },
                        { field: "broker_renewal_notice", headerName: "My RN", sortable: false, type: "download" },
                        {
                            field: "status",
                            headerName: "Renewal Status",
                            sortable: false,
                            type: "chip",
                            condition: [
                                { rule: "MISSED", color: "error" },
                                { rule: "PENDING", color: "warning" },
                                { rule: "DONE", color: "success" },
                                { rule: "FAILED", color: "secondary" },
                            ],
                        },
                        { field: "View Details", headerName: "View Details", type: "edit", url: "/renewal" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default PolicyRenewalsAll;
