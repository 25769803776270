// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, TableWidget } from "lib";

import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { useZoneRenewalsDoneListQuery } from "store/apiSlice";
function ZoneRenewalsList() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    ({ sales_zone: page.parameters.route.sales_zone } = useParams());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "Branch Renewals" }));
    }, []);
    const zoneRenewalsDoneListParams = { sales_zone: page.parameters.route.sales_zone };
    ({
        data: page.data.zoneRenewalsDoneListData,
        isFetching: page.data.zoneRenewalsDoneListDataIsFetching,
        isLoading: page.data.zoneRenewalsDoneListDataIsLoading,
    } = useZoneRenewalsDoneListQuery(zoneRenewalsDoneListParams));

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    header="Branch wise Renewals Data"
                    showSerialNumber={false}
                    showQuickFilter={false}
                    hideFooter={true}
                    xs={12}
                    rows={page.data.zoneRenewalsDoneListData || []}
                    columns={[
                        {
                            field: "Branch",
                            headerName: "Sales Branch",
                            type: "relatedlink",
                            url: "branch-renewals-list",
                        },
                        { field: "sales_head", headerName: "Sales Head" },
                        { field: "total.COUNT", headerName: "Total Renewals" },
                        { field: "total.SUM", headerName: "Total Premium", type: "currency" },
                        { field: "PENDING.COUNT", headerName: "Pending" },
                        { field: "DONE.COUNT", headerName: "Done" },
                        { field: "MISSED.COUNT", headerName: "Missed" },
                        { field: "FAILED.COUNT", headerName: "Lost" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default ZoneRenewalsList;
