// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, PMButton, FormWidget, DetailWidget, MetricWidget, TableWidget } from "lib";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { usePospListQuery } from "store/apiSlice";
function POSPCodeRenewalDetails() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const pospDetailData = useSelector((states) => states?.appStore?.pospDetailData);
    const policyRenewalsData = useSelector((states) => states?.appStore?.policyRenewalsData);
    const [filterState, setfilterState] = useState(false);
    ({ posp_code: page.parameters.route.posp_code } = useParams());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "POSP Renewal Detail" }));
    }, []);
    const pospListParams = { posp_code: "{{page.parameters.route.posp_code}}" };
    ({
        data: page.data.pospListData,
        isFetching: page.data.pospListDataIsFetching,
        isLoading: page.data.pospListDataIsLoading,
    } = usePospListQuery(pospListParams));

    const filterSubmit = (data) => {
        setFilterState(data);
    };

    const filterClick = (data) => {
        setFilterState(data);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} sx={{ my: 2 }} justifyContent="right">
                <PMButton variant="contained" label="Interact Now" onClick={filterClick}></PMButton>
            </PMGrid>
            {filterState ? (
                <FormWidget
                    sx={{ my: 2 }}
                    submitButtonLabel="Send"
                    Onsubmit={filterSubmit}
                    fieldsets={[
                        {
                            fields: [
                                { label: "Message", name: "message", type: "textarea", required: true },
                                {
                                    label: "Target",
                                    name: "target",
                                    type: "multiselect",
                                    required: true,
                                    options: [
                                        { id: "Customer", label: "Customer" },
                                        { id: "POSP", label: "POSP" },
                                        { id: "RM", label: "RM" },
                                    ],
                                },
                                {
                                    label: "Mode",
                                    name: "mode",
                                    type: "select",
                                    required: true,
                                    options: [
                                        { id: 1, label: "Email" },
                                        { id: 2, label: "WhatsApp" },
                                        { id: 3, label: "SMS" },
                                    ],
                                },
                                { label: "Attach Document", name: "dopcument", type: "file" },
                            ],
                        },
                    ]}
                ></FormWidget>
            ) : (
                ""
            )}

            <PMGrid item={true} container={true} direction="column" background="#FFFFFF">
                <DetailWidget
                    direction="row"
                    header="POSP Details"
                    md={9}
                    data={pospDetailData || []}
                    fields={[
                        { label: "POSP Name", key: "name" },
                        { label: "POSP Code", key: "posp_code" },
                        { label: "RM Name", key: "rm_name" },
                    ]}
                ></DetailWidget>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <MetricWidget
                    xs={2}
                    metricIcon="inventory"
                    header="Total Renewals"
                    metricValueColor="primary"
                    metricIconColor="primary"
                    metricValue="123"
                    metricValue2="(12.3 Lakhs)"
                ></MetricWidget>

                <MetricWidget
                    xs={2}
                    metricIcon="trending_down"
                    metricValueColor="success.main"
                    metricIconColor="success"
                    header="Due In Next 7 Days"
                    metricValue="234"
                    metricValue2="(23.4 Lakhs)"
                ></MetricWidget>

                <MetricWidget
                    xs={2}
                    metricIcon="layers_clear"
                    metricValueColor="warning.main"
                    metricIconColor="warning"
                    header="Due In Next 30 Days"
                    metricValue="345"
                    metricValue2="(34.5 Lakhs)"
                ></MetricWidget>

                <MetricWidget
                    xs={3}
                    metricIcon="domain_verification"
                    metricValueColor="error.main"
                    metricIconColor="error"
                    header="Missed In Next Last 30 Days"
                    metricValue="456"
                    metricValue2="(45.6 Lakhs)"
                ></MetricWidget>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <FormWidget
                    submitButtonLabel="Filter"
                    fullWidth="true"
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                {
                                    label: "Select Status",
                                    name: "status",
                                    type: "select",
                                    md: "6",
                                    options: [
                                        { id: "DONE", label: "Done" },
                                        { id: "DUE", label: "Pending" },
                                        { id: "MISSED", label: "Missed" },
                                        { id: "FAILED", label: "Failed" },
                                    ],
                                    required: true,
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    showSerialNumber={false}
                    showQuickFilter={false}
                    hideFooter={true}
                    xs={12}
                    rows={policyRenewalsData || []}
                    columns={[
                        { field: "due_date", headerName: "Due Date", type: "date" },
                        { field: "existing_policy_no", headerName: "Policy Number" },
                        { field: "product_type", headerName: "Product Type" },
                        { field: "insurer_name", headerName: "Insurer" },
                        { field: "customer_name", headerName: "Customer Name" },
                        { field: "total_premium", headerName: "Total Premium", type: "currency" },
                        { field: "insurer_renewal_notice", headerName: "Insurer RN" },
                        { field: "broker_renewal_notice", headerName: "My RN" },
                        {
                            field: "status",
                            headerName: "Renewal Status",
                            type: "chip",
                            condition: [
                                { rule: "MISSED", color: "error" },
                                { rule: "DUE", color: "warning" },
                                { rule: "DONE", color: "success" },
                            ],
                        },
                        { field: "View", headerName: "View", type: "edit", url: "/renewal", flex: 0.8, condition: [] },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default POSPCodeRenewalDetails;
