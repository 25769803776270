// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, StatsCardWidget2, FormWidget, TableWidget } from "lib";

import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { usePolicyRenewalsListQuery } from "store/apiSlice";

import { usePolicyMissedStatsCardQuery } from "store/apiSlice";
function PolicyRenewalsMissed() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "Missed Renewals" }));
    }, []);
    [page.parameters.renewalListFilters, page.parameters.renewalListFiltersSet] = useState({});
    [page.parameters.renewalListPagination, page.parameters.renewalListPaginationSet] = useState({ size: 50 });
    const policyRenewalsListParams = {
        status: "MISSED",
        order_by: "-due_date",
        ...page.parameters.renewalListFilters,
        ...page.parameters.renewalListPagination,
    };
    ({
        data: page.data.policyRenewalsListData,
        isFetching: page.data.policyRenewalsListDataIsFetching,
        isLoading: page.data.policyRenewalsListDataIsLoading,
    } = usePolicyRenewalsListQuery(policyRenewalsListParams));
    const policyMissedStatsCardParams = {};
    ({
        data: page.data.policyStatsCardData,
        isFetching: page.data.policyStatsCardDataIsFetching,
        isLoading: page.data.policyStatsCardDataIsLoading,
    } = usePolicyMissedStatsCardQuery(policyMissedStatsCardParams));

    const filterChange = (data) => {
        setRenewalListFilters(data);
    };

    const setRenewalListFilters = (data) => {
        var parameterValue = data;

        page.parameters.renewalListFiltersSet(parameterValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid container={true}>
                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={[
                        { label: "Total Renewals Missed", value: page.data.policyStatsCardData?.total_missed_renewal },
                        { label: "In Last 7 Days", value: page.data.policyStatsCardData?.total_missed_renewal_7_days },
                        {
                            label: "In Last 30 Days",
                            value: page.data.policyStatsCardData?.total_missed_renewal_30_days,
                        },
                    ]}
                    statsIcon=""
                    statsIconColor="primary"
                ></StatsCardWidget2>

                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={[
                        {
                            label: "Commercial Vehicle",
                            value: page.data.policyStatsCardData?.total_missed_renewal_cv || 0,
                        },
                        {
                            label: "Four Wheeler",
                            value: page.data.policyStatsCardData?.total_missed_renewal_four_wheeler,
                        },
                        {
                            label: "Two Wheeler",
                            value: page.data.policyStatsCardData?.total_missed_renewal_two_wheeler,
                        },
                    ]}
                    statsIcon=""
                    statsIconColor="success"
                ></StatsCardWidget2>

                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={[
                        {
                            label: "In Grace Period",
                            value: page.data.policyStatsCardData?.total_grace_period_renewal_15_days,
                        },
                        {
                            label: "Expiring in 7 days",
                            value: page.data.policyStatsCardData?.total_grace_period_renewal_7_days,
                        },
                        {
                            label: "Expiring in 15 Days",
                            value: page.data.policyStatsCardData?.total_grace_period_renewal_15_days,
                        },
                    ]}
                    statsIcon=""
                    statsIconColor="success"
                ></StatsCardWidget2>

                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={[
                        { label: "Total Lost", value: page.data.policyStatsCardData?.total_failed_renewal },
                        { label: "In Last 7 Days", value: page.data.policyStatsCardData?.total_failed_renewal_7_days },
                        {
                            label: "In Last 30 Days",
                            value: page.data.policyStatsCardData?.total_failed_renewal_30_days,
                        },
                    ]}
                    statsIcon=""
                    statsIconColor="warning"
                ></StatsCardWidget2>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <FormWidget
                    header="Apply Filter"
                    submitButtonLabel="Search"
                    fullWidth="true"
                    Onsubmit={filterChange}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                {
                                    label: "Customer Name",
                                    name: "existing_policy__customer_name___search",
                                    type: "text",
                                    md: "4",
                                    required: false,
                                },
                                {
                                    label: "Policy No",
                                    name: "existing_policy_number",
                                    type: "text",
                                    md: "4",
                                    required: false,
                                },
                                {
                                    label: "Product Type",
                                    name: "product_type",
                                    type: "select",
                                    md: "4",
                                    options: [
                                        { id: "", label: "---" },
                                        { id: "TWO_WHEELER", label: "Two Wheeler" },
                                        { id: "FOUR_WHEELER", label: "Four Wheeler" },
                                        { id: "CV", label: "Commercial Vehicle" },
                                    ],
                                    required: false,
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    header="Renewals List"
                    showSerialNumber={false}
                    xs={12}
                    tableApi={page.data.policyRenewalsListData || []}
                    tableParams={[page.parameters.renewalListPagination, page.parameters.renewalListPaginationSet]}
                    columns={[
                        { field: "renewal_id", headerName: "Renewal ID" },
                        { field: "due_date", headerName: "Due Date", type: "date" },
                        { field: "existing_policy_number", headerName: "Policy No." },
                        { field: "product_type", headerName: "Product Type", sortable: false },
                        { field: "insurer.name", headerName: "Insurer", sortable: false },
                        { field: "existing_policy.customer_name", headerName: "Customer Name" },
                        { field: "existing_policy.total_premium", headerName: "Total Premium", type: "currency" },
                        {
                            field: "insurer_renewal_notice",
                            headerName: "Insurer RN",
                            sortable: false,
                            type: "download",
                        },
                        { field: "broker_renewal_notice", headerName: "My RN", sortable: false, type: "download" },
                        {
                            field: "status",
                            headerName: "Renewal Status",
                            sortable: false,
                            type: "chip",
                            condition: [
                                { rule: "MISSED", color: "error" },
                                { rule: "PENDING", color: "warning" },
                                { rule: "DONE", color: "success" },
                                { rule: "FAILED", color: "secondary" },
                            ],
                        },
                        { field: "View Details", headerName: "View Details", type: "edit", url: "/renewal" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default PolicyRenewalsMissed;
