// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, PMButton, FormWidget, MetricWidget2, TableWidget } from "lib";

import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { usePospDashboardRenewalStatsQuery } from "store/apiSlice";

import { useZoneRenewalsListQuery } from "store/apiSlice";
function POSPRenewalsDashboard() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "POSP Renewals Dashboard" }));
    }, []);
    [page.parameters.pospDashboardRenewalStatsParams, page.parameters.pospDashboardRenewalStatsParamsSet] = useState(
        {}
    );
    [page.parameters.filterState, page.parameters.filterStateSet] = useState(false);
    const pospDashboardRenewalStatsParams = { ...page.parameters.pospDashboardRenewalStatsParams };
    ({
        data: page.data.pospDashboardRenewalStatsData,
        isFetching: page.data.pospDashboardRenewalStatsDataIsFetching,
        isLoading: page.data.pospDashboardRenewalStatsDataIsLoading,
    } = usePospDashboardRenewalStatsQuery(pospDashboardRenewalStatsParams));
    const zoneRenewalsListParams = {};
    ({
        data: page.data.zoneRenewalsListData,
        isFetching: page.data.zoneRenewalsListDataIsFetching,
        isLoading: page.data.zoneRenewalsListDataIsLoading,
    } = useZoneRenewalsListQuery(zoneRenewalsListParams));

    const filterSubmit = (data) => {
        pospDashboardRenewalStatsFilter(data);
    };

    const filterClick = (data) => {
        setFilterStateVariable(data);
    };

    const pospDashboardRenewalStatsFilter = (data) => {
        var parameterValue = data;

        page.parameters.pospDashboardRenewalStatsParamsSet(parameterValue);
    };

    const setFilterStateVariable = (data) => {
        page.parameters.pfilterStateSet((currentValue) => !currentValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} sx={{ my: 2 }} justifyContent="right">
                <PMButton variant="contained" label="Add Filter" onClick={filterClick}></PMButton>
            </PMGrid>
            {page.parameters.filterState ? (
                <FormWidget
                    sx={{ my: 2 }}
                    submitButtonLabel="Filter"
                    Onsubmit={filterSubmit}
                    fieldsets={[
                        {
                            fields: [
                                {
                                    label: "Interval",
                                    name: "interval",
                                    type: "select",
                                    required: true,
                                    options: [
                                        { id: "", label: "--" },
                                        { id: "this_month", label: "This Month" },
                                        { id: "next_thirty_days", label: "Next 30 Days" },
                                        { id: "next_sixty_days", label: "Next 60 Days" },
                                        { id: "last_month", label: "Last Month" },
                                    ],
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            ) : (
                ""
            )}

            <PMGrid container={true} marginTop={2}>
                <MetricWidget2
                    xs={3}
                    metricIcon="inventory"
                    metricValueColor="primary"
                    metricIconColor="primary"
                    type="2"
                    fields={
                        [
                            {
                                label: "Total Renewal Due",
                                value: page.data.pospDashboardRenewalStatsData?.status?.total_count || 0,
                            },
                            {
                                label: "Total Premium",
                                value: page.data.pospDashboardRenewalStatsData?.status?.total_sum || 0,
                                type: "currency",
                            },
                        ] || []
                    }
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricIcon="trending_down"
                    metricValueColor="success.main"
                    metricIconColor="success"
                    type="2"
                    fields={
                        [
                            { label: "Done", value: page.data.pospDashboardRenewalStatsData?.status?.DONE?.count || 0 },
                            {
                                label: "Total Premium",
                                value: page.data.pospDashboardRenewalStatsData?.status?.DONE?.sum || 0,
                                type: "currency",
                            },
                        ] || []
                    }
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricIcon="layers_clear"
                    metricValueColor="warning.main"
                    metricIconColor="warning"
                    type="2"
                    fields={
                        [
                            {
                                label: "Pending",
                                value: page.data.pospDashboardRenewalStatsData?.status?.PENDING?.count || 0,
                            },
                            {
                                label: "Total Premium",
                                value: page.data.pospDashboardRenewalStatsData?.status?.PENDING?.sum || 0,
                                type: "currency",
                            },
                        ] || []
                    }
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricIcon="domain_verification"
                    metricValueColor="error.main"
                    metricIconColor="error"
                    type="2"
                    fields={
                        [
                            {
                                label: "Missed",
                                value: page.data.pospDashboardRenewalStatsData?.status?.MISSED?.count || 0,
                            },
                            {
                                label: "Total Premium",
                                value: page.data.pospDashboardRenewalStatsData?.status?.MISSED?.sum || 0,
                                type: "currency",
                            },
                        ] || []
                    }
                ></MetricWidget2>
            </PMGrid>

            <PMGrid container={true} marginTop={2} header="Product Wise Dashboard">
                <MetricWidget2
                    xs={3}
                    metricIcon="inventory"
                    metricValueColor="primary"
                    metricIconColor="primary"
                    type="2"
                    fields={
                        [
                            {
                                label: "Commercial Vehicle",
                                value: page.data.pospDashboardRenewalStatsData?.product_type?.CV?.count || 0,
                            },
                            {
                                label: "Total Premium",
                                value: page.data.pospDashboardRenewalStatsData?.product_type?.CV?.sum || 0,
                                type: "currency",
                            },
                        ] || []
                    }
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricIcon="layers_clear"
                    metricValueColor="warning.main"
                    metricIconColor="warning"
                    type="2"
                    fields={
                        [
                            {
                                label: "Four Wheeler",
                                value: page.data.pospDashboardRenewalStatsData?.product_type?.FOUR_WHEELER?.count || 0,
                            },
                            {
                                label: "Total Premium",
                                value: page.data.pospDashboardRenewalStatsData?.product_type?.FOUR_WHEELER?.sum || 0,
                                type: "currency",
                            },
                        ] || []
                    }
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricIcon="domain_verification"
                    metricValueColor="error.main"
                    metricIconColor="error"
                    type="2"
                    fields={
                        [
                            {
                                label: "Two Wheeler",
                                value: page.data.pospDashboardRenewalStatsData?.product_type?.TWO_WHEELER?.count || 0,
                            },
                            {
                                label: "Total Premium",
                                value: page.data.pospDashboardRenewalStatsData?.product_type?.TWO_WHEELER?.sum || 0,
                                type: "currency",
                            },
                        ] || []
                    }
                ></MetricWidget2>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    header="Zone wise Renewals Data"
                    showSerialNumber={false}
                    showQuickFilter={false}
                    hideFooter={true}
                    xs={12}
                    rows={page.data.zoneRenewalsListData || []}
                    columns={[
                        { field: "ZONE", headerName: "Zone", type: "relatedlink", url: "zone-renewals-list" },
                        { field: "total.COUNT", headerName: "Total Renewals" },
                        { field: "total.SUM", headerName: "Total Premium", type: "currency" },
                        { field: "PENDING.COUNT", headerName: "Pending" },
                        { field: "DONE.COUNT", headerName: "Done" },
                        { field: "MISSED.COUNT", headerName: "Missed" },
                        { field: "FAILED.COUNT", headerName: "Lost" },
                        { field: "View", headerName: "Actions", type: "edit" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default POSPRenewalsDashboard;
