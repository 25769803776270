const renewalDetailsFormatter = (data, renewalData) => {

  const dataSet = data;
  console.log(data, "data")
  dataSet["files"] = data["document"]||[]
  dataSet["content"] = data["message"]
  dataSet["type"] = data["mode"]
  dataSet["target"] = "POSP"
  let date = new Date()
  dataSet["actual_time"] = date.toISOString()
  dataSet["entity_type"] = "POSP"
  dataSet["entity_id"] = renewalData?.id
  dataSet["subject"] = renewalData?.rm?.name + "Message"
  dataSet["receiver"] = renewalData?.posp_email
  dataSet["agent_id"] = renewalData?.rm?.sales_head_id
  dataSet["interaction_with"] = "POSP"
  dataSet["interaction_status"] = "DONE"
  return dataSet;
};

  export default renewalDetailsFormatter;
  