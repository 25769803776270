// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, TableWidget } from "lib";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { useRmRenewalsDoneListQuery } from "store/apiSlice";
function RMRenewalsList() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const rmRenewalsDoneListData = useSelector((states) => states?.appStore?.rmRenewalsDoneListData);
    ({ id: page.parameters.route.id } = useParams());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "RM Renewals List" }));
    }, []);
    [page.parameters.rmRenewalsDoneListPagination, page.parameters.rmRenewalsDoneListPaginationSet] = useState({
        size: 50,
    });
    const rmRenewalsDoneListParams = {
        rm_id: page.parameters.route.id,
        ...page.parameters.rmRenewalsDoneListPagination,
    };
    ({
        data: page.data.rmRenewalsDoneListData,
        isFetching: page.data.rmRenewalsDoneListDataIsFetching,
        isLoading: page.data.rmRenewalsDoneListDataIsLoading,
    } = useRmRenewalsDoneListQuery(rmRenewalsDoneListParams));

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    header="RM Wise Renewals Data"
                    showSerialNumber={false}
                    showQuickFilter={false}
                    hideFooter={true}
                    xs={12}
                    tableApi={page.data.rmRenewalsDoneListData || []}
                    tableParams={[
                        page.parameters.rmRenewalsDoneListPagination,
                        page.parameters.rmRenewalsDoneListPaginationSet,
                    ]}
                    columns={[
                        { field: "due_date", headerName: "Due Date", type: "date" },
                        {
                            field: "posp_code",
                            headerName: "POSP Code",
                            type: "relatedlink",
                            url: "posp-renewals",
                            urlField: "posp_id",
                        },
                        { field: "existing_policy_number", headerName: "Policy Number" },
                        { field: "product_type", headerName: "Product Type" },
                        { field: "insurer.name", headerName: "Insurer" },
                        { field: "existing_policy.customer_name", headerName: "Customer Name" },
                        { field: "existing_policy.total_premium", headerName: "Total Premium", type: "currency" },
                        {
                            field: "status",
                            headerName: "Renewal Status",
                            type: "chip",
                            condition: [
                                { rule: "MISSED", color: "error" },
                                { rule: "PENDING", color: "warning" },
                                { rule: "DONE", color: "success" },
                                { rule: "FAILED", color: "secondary" },
                            ],
                        },
                        { field: "insurer_renewal_notice", headerName: "Insurer RN", type: "download" },
                        { field: "broker_renewal_notice", headerName: "MY RN", type: "download" },
                        { field: "view", headerName: "View", type: "edit", url: "/renewal" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default RMRenewalsList;
