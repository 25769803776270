// @ts-nocheck

import { FormWidget, PMGrid, StatsCardWidget2, TableWidget } from "lib";
import { useEffect, useState } from "react";

import { errorSerializer } from "helperFn/errorSerializer";
import { useSnackbar } from "notistack";

import { useDispatch } from "react-redux";
import { usePolicyExportMutation } from "store/apiSlice";
import { setStore } from "store/appSlice";
import { default as policyRenewalsListFilterFormatter } from "transformations/policyRenewalsListFilterFormatter";

import { usePolicyOfThisMonthQuery } from "store/apiSlice";

import { usePolicyListQuery } from "store/apiSlice";
function PolicyList() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [policyExportMutationTrigger, policyExportMutationResult] = usePolicyExportMutation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "Policy List" }));
    }, []);
    [page.parameters.policyListFilters, page.parameters.policyListFiltersSet] = useState({});
    [page.parameters.policyListPagination, page.parameters.policyListPaginationSet] = useState({ size: 50 });
    const policyOfThisMonthParams = {};
    ({
        data: page.data.policyThisMonth,
        isFetching: page.data.policyThisMonthIsFetching,
        isLoading: page.data.policyThisMonthIsLoading,
    } = usePolicyOfThisMonthQuery(policyOfThisMonthParams));
    const policyListParams = {
        order_by: "-start_date",
        ...page.parameters.policyListFilters,
        ...page.parameters.policyListPagination,
    };
    ({
        data: page.data.policyListData,
        isFetching: page.data.policyListDataIsFetching,
        isLoading: page.data.policyListDataIsLoading,
    } = usePolicyListQuery(policyListParams));

    const filterChange = (data) => {
        setPolicyListFilters(data);
    };

    const downloadExportFIle = (data) => {
        window.open(data["export_file_url"], "_blank");
    };

    const policyExport = async (data) => {
        var apiData = data;

        if (apiData) {
            policyExportMutationTrigger({ ...apiData })
                .unwrap()
                .then((data) => {
                    downloadExportFIle(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const exportPolicy = (data) => {
        policyExport(data);
    };

    const setPolicyListFilters = (data) => {
        var parameterValue = data;
        parameterValue = policyRenewalsListFilterFormatter(parameterValue);
        page.parameters.policyListFiltersSet(parameterValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} marginTop={2} marginBottom={4}>
                <StatsCardWidget2
                    xs={4}
                    statsIcon="airport_shuttle"
                    statsIconColor="primary"
                    type="4"
                    fields={[
                        { label: "Total Policy", value: page.data.policyThisMonth?.TOTAL?.COUNT || 0 },
                        { value: page.data.policyThisMonth?.CV?.COUNT || 0, label: "CV" },
                        { value: page.data.policyThisMonth?.FOUR_WHEELER?.COUNT || 0, label: "Four Wheeler" },
                        { value: page.data.policyThisMonth?.TWO_WHEELER?.COUNT || 0, label: "Two Wheeler" },
                    ]}
                ></StatsCardWidget2>

                <StatsCardWidget2
                    xs={4}
                    statsIcon=""
                    statsIconColor="warning"
                    type="4"
                    fields={[
                        { label: "Total Policy", value: page.data.policyThisMonth?.TOTAL?.SUM || 0, type: "currency" },
                        { value: page.data.policyThisMonth?.CV?.SUM || 0, label: "CV", type: "currency" },
                        {
                            value: page.data.policyThisMonth?.FOUR_WHEELER?.SUM || 0,
                            label: "Four Wheeler",
                            type: "currency",
                        },
                        {
                            value: page.data.policyThisMonth?.TWO_WHEELER?.SUM || 0,
                            label: "Two Wheeler",
                            type: "currency",
                        },
                    ]}
                ></StatsCardWidget2>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <FormWidget
                    submitButtonLabel="Export"
                    header="Export Policies"
                    fullWidth="true"
                    Onsubmit={exportPolicy}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                { label: "End Date From", name: "end_date___gte", type: "date", required: false },
                                { label: "End Date To", name: "end_date___lte", type: "date", required: false },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <FormWidget
                    submitButtonLabel="Search"
                    header="Apply Filter"
                    fullWidth="true"
                    showClearButton={true}
                    clearButtonLabel="Reset"
                    Onsubmit={filterChange}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                {
                                    label: "Customer Name",
                                    name: "customer_name___search",
                                    type: "text",
                                    required: false,
                                },
                                { label: "Policy No", name: "policy_number", type: "text", required: false },
                                {
                                    label: "Product Type",
                                    name: "product_type",
                                    type: "select",
                                    options: [
                                        { id: "", label: "---" },
                                        { id: "TWO_WHEELER", label: "Two Wheeler" },
                                        { id: "FOUR_WHEELER", label: "Four Wheeler" },
                                        { id: "CV", label: "Commercial Vehicle" },
                                    ],
                                    required: false,
                                },
                                { label: "End Date From", name: "end_date___gte", type: "date", required: false },
                                { label: "End Date To", name: "end_date___lte", type: "date", required: false },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>

            <PMGrid container={true} xs={12}>
                <TableWidget
                    xs={12}
                    header="Insurance Policy List"
                    tableApi={page.data.policyListData || []}
                    tableParams={[page.parameters.policyListPagination, page.parameters.policyListPaginationSet]}
                    columns={[
                        { field: "policy_number", flex: 0.9, headerName: "Policy No" },
                        { field: "customer_name", headerName: "Customer Name" },
                        { field: "coverage_type", flex: 0.6, headerName: "Coverage Type" },
                        { field: "product_type", flex: 0.6, headerName: "Product Type" },
                        { field: "insurer.name", headerName: "Insurer", sortable: false },
                        { field: "total_premium", flex: 0.7, headerName: "Total Premium", type: "currency" },
                        { field: "start_date", flex: 0.6, headerName: "Start Date", type: "date" },
                        { field: "end_date", flex: 0.6, headerName: "End Date", type: "date" },
                        { field: "policy_document", flex: 0.5, headerName: "PDF", sortable: false, type: "download" },
                        {
                            field: "View",
                            headerName: "View",
                            type: "edit",
                            url: "/policy-edit",
                            flex: 0.6,
                            condition: [],
                        },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default PolicyList;
