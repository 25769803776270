// @ts-nocheck

import { ChartWidget, FormWidget, MetricWidget2, PMGrid, TableWidget } from "lib";
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { setStore } from "store/appSlice";
import { default as durationFormatter } from "transformations/durationFormatter";

import { usePolicyDateWiseQuery } from "store/apiSlice";

import { usePolicyInsurerWiseQuery } from "store/apiSlice";

import { usePolicyProductWiseQuery } from "store/apiSlice";

import { usePolicyExtractedDateWiseQuery } from "store/apiSlice";
function PolicyDashboard() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "Policy Dashboard" }));
    }, []);
    [page.parameters.policyListFilters, page.parameters.policyListFiltersSet] = useState({});
    [page.parameters.policyListPagination, page.parameters.policyListPaginationSet] = useState({ size: 5 });
    [page.parameters.policyDashboardFilters, page.parameters.policyDashboardFiltersSet] = useState({});
    const policyDateWiseParams = {
        group_by_values: "created_at___as_date,product_type",
        agg_function: "SUM",
        agg_on: "total_premium",
        ...page.parameters.policyDashboardFilters,
    };
    ({
        data: page.data.policyDateWiseData,
        isFetching: page.data.policyDateWiseDataIsFetching,
        isLoading: page.data.policyDateWiseDataIsLoading,
    } = usePolicyDateWiseQuery(policyDateWiseParams));
    const policyInsurerWiseParams = {
        group_by_values: "insurer__name",
        agg_function: "SUM",
        agg_on: "total_premium",
        ...page.parameters.policyDashboardFilters,
    };
    ({
        data: page.data.policyInsurerWiseData,
        isFetching: page.data.policyInsurerWiseDataIsFetching,
        isLoading: page.data.policyInsurerWiseDataIsLoading,
    } = usePolicyInsurerWiseQuery(policyInsurerWiseParams));
    const policyProductWiseParams = { group_by_values: "product_type", ...page.parameters.policyDashboardFilters };
    ({
        data: page.data.policyProductWiseData,
        isFetching: page.data.policyProductWiseDataIsFetching,
        isLoading: page.data.policyProductWiseDataIsLoading,
    } = usePolicyProductWiseQuery(policyProductWiseParams));
    const policyExtractedDateWiseParams = {
        group_by_values: "created_at___as_date",
        ...page.parameters.policyDashboardFilters,
    };
    ({
        data: page.data.policyExtractedDatewiseData,
        isFetching: page.data.policyExtractedDatewiseDataIsFetching,
        isLoading: page.data.policyExtractedDatewiseDataIsLoading,
    } = usePolicyExtractedDateWiseQuery(policyExtractedDateWiseParams));

    const filterChange = (data) => {
        setpolicyDashboardData(data);
    };

    const setpolicyDashboardData = (data) => {
        var parameterValue = data;
        parameterValue = durationFormatter(parameterValue);
        page.parameters.policyDashboardFiltersSet(parameterValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid marginBottom={2}>
                <FormWidget
                    submitOnChange={true}
                    showSubmitButton={false}
                    fullWidth={true}
                    Onsubmit={filterChange}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                {
                                    name: "created_at",
                                    type: "togglebuttongroup",
                                    exclusive: true,
                                    options: [
                                        { id: "this_month", label: "This Month" },
                                        { id: "last_month", label: "Last Month" },
                                        { id: "next_month", label: "Next Month" },
                                        { id: "last_7_days", label: "Last Seven Days" },
                                        { id: "next_7_days", label: "Next Seven Days" },
                                    ],
                                    required: false,
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>

            <PMGrid container={true} xs={12}>
                <PMGrid width="60%">
                    <ChartWidget
                        width="100%"
                        type="line"
                        showLegend={true}
                        header="Policy Inwarding Data Date and Product Wise"
                        headerBackgroundColor="primary.light"
                        legendPosition="right"
                        datakey="SUM"
                        datasets={[Object.values(page.data.policyDateWiseData || {})]}
                        labels={Object.keys(page.data.policyDateWiseData || {}) || []}
                        showTooltip={true}
                        options={{
                            aspectRatio: 2.7,
                            scales: { x: { grid: { display: false } }, y: { grid: { display: false } } },
                            legend: { display: true, position: "bottom" },
                            borderWidth: 1,
                        }}
                        borderRadius={3}
                    ></ChartWidget>
                </PMGrid>

                <PMGrid width="40%">
                    <TableWidget
                        headerBackgroundColor="secondary.light"
                        header="Insurer wise Policy Count"
                        rows={Object.keys(page.data.policyInsurerWiseData || {}).reduce((acc, key, item) => {
                            return [
                                ...acc,
                                {
                                    insurer: key,
                                    count: Object.values(page.data.policyInsurerWiseData || {})[item]["COUNT"],
                                    total_premium: Object.values(page.data.policyInsurerWiseData || {})[item]["SUM"],
                                },
                            ];
                        }, [])}
                        tableParams={[page.parameters.policyListPagination, page.parameters.policyListPaginationSet]}
                        paginationMode="client"
                        pageSizeOptions={[5, 10]}
                        columns={[
                            { field: "insurer", flex: 2, headerName: "Insurer" },
                            { field: "count", flex: 0.6, headerName: "Count" },
                            { field: "total_premium", flex: 1, type: "currency", headerName: "Total Premium" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </PMGrid>

            <PMGrid container={true} sx={{ marginTop: 2 }} xs={12}>
                <PMGrid width="20%" container={true} gap={1}>
                    <MetricWidget2
                        width="100%"
                        metricIcon="text_snippet"
                        metricValueColor="success"
                        metricIconColor="success"
                        header="Total Policy"
                        type="2"
                        fields={[
                            {
                                label: "Total Policy",
                                value:
                                    Object.values(page.data.policyProductWiseData || {}).reduce((acc, item, index) => {
                                        return (acc += item["COUNT"]);
                                    }, 0) || 0,
                            },
                            { label: "", value: "" },
                        ]}
                    ></MetricWidget2>

                    <MetricWidget2
                        width="100%"
                        metricIcon="currency_rupee"
                        metricValueColor="success"
                        metricIconColor="success"
                        header="Total Premium"
                        metricPrefix="₹"
                        type="2"
                        fields={[
                            {
                                label: "Total Premium",
                                value:
                                    Object.values(page.data.policyInsurerWiseData || {}).reduce((acc, item, index) => {
                                        return (acc += item["SUM"]);
                                    }, 0) || 0,
                                type: "currency",
                            },
                            { label: "", value: "" },
                        ]}
                    ></MetricWidget2>
                </PMGrid>

                <PMGrid width="50%">
                    <ChartWidget
                        header="Policy Inwarding Data Date Wise"
                        headerBackgroundColor="primary.light"
                        width="100%"
                        type="bar"
                        showLegend={true}
                        legendPosition="right"
                        legendText="Policy Count"
                        datakey="COUNT"
                        datasets={[Object.values(page.data.policyExtractedDatewiseData || {})]}
                        labels={Object.keys(page.data.policyExtractedDatewiseData || {}) || []}
                        showTooltip={true}
                        options={{
                            aspectRatio: 1.89,
                            scales: { x: { grid: { display: false } }, y: { grid: { display: false } } },
                            legend: { display: false },
                            plugins: {},
                            borderWidth: 1,
                        }}
                        borderRadius={3}
                    ></ChartWidget>
                </PMGrid>

                <PMGrid width="30%">
                    <ChartWidget
                        header="Policy Inwarding Data Product Wise"
                        headerBackgroundColor="secondary.light"
                        width="100%"
                        type="pie"
                        showLegend={true}
                        legendPosition="bottom"
                        datakey="COUNT"
                        datasets={[Object.values(page.data.policyProductWiseData || {})]}
                        labels={Object.keys(page.data.policyProductWiseData || {}) || []}
                        showTooltip={true}
                        options={{
                            aspectRatio: 1.1,
                            scales: {
                                x: { grid: { display: false }, ticks: { display: false } },
                                y: { grid: { display: false }, ticks: { display: false } },
                            },
                            legend: { display: false },
                            plugins: {},
                            borderWidth: 1,
                        }}
                        borderRadius={3}
                    ></ChartWidget>
                </PMGrid>
            </PMGrid>
        </div>
    );
}
export default PolicyDashboard;
