// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, PMText, NotesWidget } from "lib";

import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";
function Help() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "Help" }));
    }, []);

    return (
        <div className="builder_wrapper">
            <PMGrid item={true} container={true} direction="column" background="#FFFFFF">
                <PMGrid sx={{ marginTop: 2 }}>
                    <PMText varaint="h5" sx={{ opacity: 0.5 }} color="info" children="Policy Inwarding"></PMText>
                </PMGrid>

                <NotesWidget
                    header="Create Policy"
                    content={[
                        "1. Select Policy Inwarding from the navigation bar.",
                        "2. Click on 'Create Policy'.",
                        "3. Fill in all the required data to create an insurance policy. Also, Upload the insurance policy.",
                        "4. Click 'Submit'. (If an error occurs, please ensure that all mandatory fields are filled in correctly.)",
                    ]}
                ></NotesWidget>

                <NotesWidget
                    header="View Policy"
                    content={[
                        "1. The submitted policy will be created and can be viewed in the View Policy Section.",
                        "2. Click on 'View Policy' in the Policy Inwarding section.",
                        "3. In the Insurance Policy list, click on 'View' to display the data for the selected policy on the View Policy page.",
                        "4. Click the download button in the insurance policy list to download the uploaded policy.",
                    ]}
                ></NotesWidget>

                <NotesWidget
                    header="Extract Policy"
                    content={[
                        "1. If the user needs to extract any insurance policy, follow these steps:",
                        "(a). Click on the 'Extract Policy' section.",
                        "(b). Click 'Upload' and select the policy PDF from your computer.",
                        "(c). Once the policy is selected, click 'Extract' to extract the policy.",
                        "2. When the PDF is extracted, the Edit page with the extracted data will be displayed.",
                        "(a). If any changes are required, the user can edit the data.",
                        "(b). If all the extracted data is correct, the user can simply submit the page.",
                        "(c). The extracted policy can be viewed on the “View Policy” page.",
                    ]}
                ></NotesWidget>

                <PMGrid sx={{ marginTop: 2 }}>
                    <PMText varaint="h5" sx={{ opacity: 0.5 }} color="info" children="Policy Renewals"></PMText>
                </PMGrid>

                <NotesWidget
                    header="Automatic Renewals Generation"
                    content={[
                        "1. The Renewals for the policies that have been created or extracted are visible in this section. The system will generate “Automatic Renewals” for these policies, which are scheduled to expire in next 60 days, based on the policy end date.",
                    ]}
                ></NotesWidget>

                <NotesWidget
                    header="Dashboard"
                    content={[
                        "1. Renewal – This Month",
                        "(a). The displayed metrics provide an overview of the renewal cycle, showcasing the Total Renewals and the corresponding Premium amount that is currently due. Additionally, the metrics also show the percentage of pending renewals in relation to the total. ",
                        "(b). The Renewals Done card showcases all policies that have been renewed till date, along with their total premium amount. ",
                        "(c). The Renewals Pending card presents the list of policies that have not yet been renewed, along with the displayed premium amount. It is important to note that these policies will have a remaining renewal period of 60 days, at max. ",
                        "(d). The Renewals Missed card displays the count of policies that have been expired without getting renewed. It also shows the corresponding Total Premium amount associated with these missed renewals.",
                        "2. Upcoming Renewals (Next 7 Days)",
                        "(a). The cards in this section denote the bifurcation of products and the upcoming renewals-Count & premium in the next 7 days.",
                    ]}
                ></NotesWidget>

                <NotesWidget
                    header="Pending"
                    content={[
                        "1. The pending section displays a list of policies that are pending for renewals (within 60 days of policy expiry).",
                        "2. Insurer Renewal Notice and My Renewal Notice can be downloaded by clicking the Download CTA (Call to Action). Currently, owing to this being our demo environment, data displayed on both the renewal notices might not be accurate and are solely for display purposes only.",
                        "3. Clicking on 'View Details' from the list opens a view page with detailed information regarding the selected policy in the pending renewals section.",
                        "4. If the renewal policy is not purchased by the customer, system users can designate the policy as 'failed' by clicking the 'Mark as Failed' CTA.",
                        "5. On the “View Detail” page, there is an 'Automated Reminders' section that displays the reminders scheduled to be sent automatically during the Renewals Due Period. No communications will be sent to the customer on the demo environment.",
                    ]}
                ></NotesWidget>

                <NotesWidget
                    header="Missed"
                    content={[
                        "1. The Missed section indicates that the policy has not been renewed and it has expired. After expiry of the policy, the renewal category will change to Missed. ",
                        "2. A Grace Period of 15 days has been implemented for all Missed renewals, starting from the policy end date. If a policy is not renewed within this grace period, it will be categorized as a Lost Renewal. ",
                        "3. By clicking on 'View Details', users can access the policy details. The page includes option to update the Renewal Status (Done, Pending, Missed, and Failed).",
                    ]}
                ></NotesWidget>

                <NotesWidget
                    header="Update Renewal Status"
                    content={[
                        "1. The 'Update Renewal Status' section on the 'View Details' page allows you to change the renewal status either to Failed or Done. ",
                        "(a). Choose 'Mark as failed' or 'Mark as done' from the dropdown menu. ",
                        "(b). If 'Mark as failed' is selected, provide remarks for marking it as failed. ",
                        "(c). If 'Mark as done' is selected, enter the policy number for the new policy. ",
                        "(d). Click on Submit to mark the renewal as done or failed. ",
                    ]}
                ></NotesWidget>
            </PMGrid>
        </div>
    );
}
export default Help;
