
const renewalDetailsFormatter = (data, renewalData) => {

  const dataSet = data;
  let kycData = {kyc:[]}
  console.log(data, "data")
  kycData["kyc"].push({type:"AADHAR",data:JSON.stringify({AADHAR_NO:data?.adhar_number}),document:data?.adhar_document})
  kycData["kyc"].push({type:"PAN",data:JSON.stringify({"PAN_NO":data?.pan_number}),document:data?.pan_document})
  return kycData;
};

  export default renewalDetailsFormatter;
  