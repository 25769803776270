// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, TableWidget } from "lib";

import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { usePospFetchQuery } from "store/apiSlice";
function POSPKyc() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "POSP KYC" }));
    }, []);
    [page.parameters.pospFetchPagination, page.parameters.pospFetchPaginationSet] = useState({ size: 50 });
    const pospFetchParams = { ...page.parameters.pospFetchPagination };
    ({
        data: page.data.pospFetchData,
        isFetching: page.data.pospFetchDataIsFetching,
        isLoading: page.data.pospFetchDataIsLoading,
    } = usePospFetchQuery(pospFetchParams));

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    header="POSP List"
                    showSerialNumber={true}
                    xs={12}
                    tableApi={page.data.pospFetchData || []}
                    tableParams={[page.parameters.pospFetchPagination, page.parameters.pospFetchPaginationSet]}
                    columns={[
                        { field: "name", headerName: "POSP Name" },
                        { field: "posp_code", headerName: "POSP Code" },
                        { field: "rm.name", headerName: "RM" },
                        { field: "posp_email", headerName: "Email" },
                        { field: "is_kyc_completed", headerName: "Is KYC Done" },
                        {
                            field: "Verify",
                            headerName: "Verify",
                            type: "edit",
                            url: "kyc-verification",
                            condition: ["varifiable", true],
                        },
                        { headerName: "Edit", type: "edit", url: "kyc", condition: ["editable", false] },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default POSPKyc;
