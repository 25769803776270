
import { addDays, format as formatDate, subDays } from "date-fns";
const thisMonth = (field) => {
    let current_date = new Date()
    let gte = new Date(current_date.getFullYear(), current_date.getMonth(), 1)
    let lte = new Date(current_date.getFullYear(), current_date.getMonth()+1, 0)
    let res = {}
    res[field+"___gte"] = formatDate(gte, "yyyy-MM-dd") + "T00:00:00.000Z";
    res[field+"___lte"] = formatDate(lte, "yyyy-MM-dd") + "T00:00:00.000Z";
    res[field] = undefined
    console.log(field,"field")
    console.log(res, "response") 
    return res
}

const lastMonth = (field) => {
    let current_date = new Date()
    let gte = new Date(current_date.getFullYear(), current_date.getMonth()-1, 1)
    let lte = new Date(current_date.getFullYear(), current_date.getMonth(), 0)
    let res = {}
    res[field+"___gte"] = formatDate(gte, "yyyy-MM-dd") + "T00:00:00.000Z";
    res[field+"___lte"] = formatDate(lte, "yyyy-MM-dd") + "T00:00:00.000Z";
    res[field] = undefined
    console.log(res, "response") 
    return res
}
const nextMonth = (field) => {
    let current_date = new Date()
    let gte = new Date(current_date.getFullYear(), current_date.getMonth()+1, 1)
    let lte = new Date(current_date.getFullYear(), current_date.getMonth()+2, 0)
    let res = {}
    res[field+"___gte"] = formatDate(gte, "yyyy-MM-dd") + "T00:00:00.000Z";
    res[field+"___lte"] = formatDate(lte, "yyyy-MM-dd") + "T00:00:00.000Z";
    res[field] = undefined
    console.log(res, "response")
    return res 
}
const lastDaysDate = (field, days) => {
    let current_date = new Date()
    let before_date = subDays(Date.now(), days)
    let res = {}
    res[field+"___gte"] = formatDate(before_date , "yyyy-MM-dd") + "T00:00:00.000Z";
    res[field+"___lte"] = formatDate(current_date, "yyyy-MM-dd") + "T00:00:00.000Z";
    res[field] = undefined
    console.log(res)
    return res
}
const nextDaysDate = (field, days) => {
    let current_date = new Date()
    let after_date = addDays(Date.now(),days)
    let res = {}
    res[field+"___gte"] = formatDate(current_date, "yyyy-MM-dd") + "T00:00:00.000Z";
    res[field+"___lte"] = formatDate(after_date, "yyyy-MM-dd") + "T00:00:00.000Z";
    res[field] = undefined
    console.log(res)
    return res
}

function dateFilter (filter,dur) {
    // let splited_data = filter.split("___")
    // let duration = splited_data[1]
    // let field = splited_data[0]
    console.log(filter, dur,typeof(dur),"===")
    let field = filter
    let duration = (typeof(dur) === "object"?dur[0]: dur )
    console.log(field, duration)
    const duration_mapper = {
        "this_month":thisMonth,
        "last_month":lastMonth,
        "next_month":nextMonth,
    }

    if (duration_mapper[duration]){

        return duration_mapper[duration](field)
    }

    let duration_data = duration.split("_")

    console.log(duration_data)

    if(duration_data[0] == "last"){
        return lastDaysDate(field, duration_data[1])
    }
    else{
        return nextDaysDate(field, duration_data[1])

    }
}
function durationFormatter(data){
    Object.keys(data).map((item)=>{
        if (data[item]){

            let newKey = dateFilter(item, data[item])
            data = {...data, ...newKey}
        }
    })
    
    return Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v != ""));
}
export default durationFormatter;