// @ts-nocheck

import { useEffect, useState } from "react";
import { FormWidget } from "lib";

import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useEmployeeCreateMutation } from "store/apiSlice";
import { useNavigate } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { useEmployeeListQuery } from "store/apiSlice";
function EmployeeCreate() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [employeeCreateMutationTrigger, employeeCreateMutationResult] = useEmployeeCreateMutation();
    const history = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "Create Employee" }));
    }, []);
    const employeeListParams = {};
    ({
        data: page.data.employeeListData,
        isFetching: page.data.employeeListDataIsFetching,
        isLoading: page.data.employeeListDataIsLoading,
    } = useEmployeeListQuery(employeeListParams));

    const leadNavigate = (data) => {
        history("/employee-list");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Employee Created Successfully.", { variant: "success" });
    };

    const employeeCreate = async (data) => {
        var apiData = data;

        if (apiData) {
            employeeCreateMutationTrigger({ ...apiData })
                .unwrap()
                .then((data) => {
                    showSuccessAlert(data);
                    leadNavigate(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const formSubmitApi = (data) => {
        employeeCreate(data);
    };

    return (
        <div className="builder_wrapper">
            <FormWidget
                sx={{ my: 2 }}
                header="Create Employee"
                submitButtonLabel="Create Employee"
                Onsubmit={formSubmitApi}
                fieldsets={[
                    {
                        direction: "row",
                        fields: [
                            { label: "Employee Name", name: "name", type: "text", required: true },
                            {
                                label: "Phone",
                                name: "phone",
                                type: "phone",
                                required: true,
                                validation: { minLength: 10, maxLength: 10, pattern: "^\\d+$" },
                            },
                            { label: "Email", name: "email", type: "email", required: true },
                        ],
                    },
                    {
                        direction: "row",
                        header: "Address Details",
                        fields: [
                            { label: "Address", name: "address", type: "text" },
                            { label: "City", name: "city", type: "text" },
                            { label: "State", name: "state", type: "text" },
                            {
                                label: "Pincode",
                                name: "pincode",
                                type: "text",
                                validation: { minLength: 6, maxLength: 6, pattern: "^\\d+$" },
                            },
                        ],
                    },
                    {
                        direction: "row",
                        header: "Account Details",
                        fields: [
                            { label: "Bank Name", name: "bank_name", type: "text" },
                            {
                                label: "A/C No.",
                                name: "bank_account_name",
                                type: "text",
                                validation: { minLength: 10, maxLength: 15, pattern: "^\\d+$" },
                            },
                            {
                                label: "IFSC Code",
                                name: "bank_ifsc_code",
                                type: "text",
                                validation: { minLength: 11, maxLength: 11, pattern: "^[A-Za-z]{4}[0]{1}[0-9]{6}$" },
                            },
                            {
                                label: "Aadhaar No",
                                name: "aadhar",
                                type: "text",
                                validation: { minLength: 12, maxLength: 12, pattern: "^[1-9]{1}[0-9]{11}$" },
                            },
                            {
                                label: "Pan No",
                                name: "pan",
                                type: "text",
                                validation: {
                                    minLength: 10,
                                    maxLength: 10,
                                    pattern: "^[A-Za-z]{3}[CPHFATBLJGcphfatbljg]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}$",
                                },
                            },
                        ],
                    },
                    {
                        direction: "row",
                        header: "Department Details",
                        fields: [
                            {
                                label: "Role",
                                name: "role",
                                type: "select",
                                options: [
                                    { id: "CXO", label: "CXO" },
                                    { id: "RM", label: "RM" },
                                ],
                                required: true,
                            },
                            {
                                label: "Department",
                                name: "department",
                                type: "select",
                                options: [
                                    { id: "OPERATIONS", label: "Operations" },
                                    { id: "SALES", label: "Sales" },
                                    { id: "BUSINESS", label: "Business" },
                                ],
                                required: true,
                            },
                            {
                                label: "Reporting Manager",
                                name: "manager",
                                type: "select",
                                options: page.data.employeeListData?.items || [],
                                required: true,
                            },
                            { label: "Is Active", name: "status", type: "checkbox" },
                        ],
                    },
                ]}
            ></FormWidget>
        </div>
    );
}
export default EmployeeCreate;
