// @ts-nocheck

import { useEffect, useState } from "react";
import { PMCard, PMGrid, PMText, PMButton, DetailWidget, FormWidget, TableWidget } from "lib";

import { useSelector } from "react-redux";
import { jsonToFormData } from "helperFn/formData";
import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useInteractionCreateMutation } from "store/apiSlice";
import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { useRenewalDetailFollowupQuery } from "store/apiSlice";

import { useRenewalInteractionHistoryQuery } from "store/apiSlice";
function FollowupDetail() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const renewalInteractionHistoryData = useSelector((states) => states?.appStore?.renewalInteractionHistoryData);
    const { enqueueSnackbar } = useSnackbar();
    const [interactionCreateMutationTrigger, interactionCreateMutationResult] = useInteractionCreateMutation();
    ({ id: page.parameters.route.id } = useParams());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStore({ currentPageTitle: "Interaction Page" }));
    }, []);
    const renewalDetailFollowupParams = { id: page.parameters.route.id };
    ({
        data: page.data.renewalDetailFollowupData,
        isFetching: page.data.renewalDetailFollowupDataIsFetching,
        isLoading: page.data.renewalDetailFollowupDataIsLoading,
    } = useRenewalDetailFollowupQuery(renewalDetailFollowupParams));
    const renewalInteractionHistoryParams = {
        entity_id: page.data.renewalDetailFollowupData?.id,
        entity_type: "RENEWAL",
    };
    ({
        data: page.data.renewalInteractionHistoryData,
        isFetching: page.data.renewalInteractionHistoryDataIsFetching,
        isLoading: page.data.renewalInteractionHistoryDataIsLoading,
    } = useRenewalInteractionHistoryQuery(renewalInteractionHistoryParams));

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Interaction Created", { variant: "success" });
    };

    const interactionCreate = async (data) => {
        var apiData = data;

        if (apiData) {
            interactionCreateMutationTrigger(jsonToFormData({ ...apiData }))
                .unwrap()
                .then((data) => {
                    refetchRenewalInteractionHistorySkip(data);
                    showSuccessAlert(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const interactionSubmitApi = (data) => {
        interactionCreate(data);
    };

    return (
        <div className="builder_wrapper">
            <PMCard sx={{ minWidth: "100%", backgroundColor: "background.paper" }} direction="row">
                <PMGrid container={true} md={12} direction="row">
                    <PMGrid md="6" direction="column">
                        <PMText children="Call Duration" variant="h6"></PMText>

                        <PMText children="10: 40" variant="h4"></PMText>
                    </PMGrid>

                    <PMGrid md={6}>
                        <PMGrid direction="row" sx={{ justifyContent: "end", marginTop: 2, textAlign: "right" }}>
                            <PMButton
                                label="End Call"
                                sx={{ marginInline: 1 }}
                                variant="contained"
                                color="error"
                            ></PMButton>

                            <PMButton
                                label="Transfer Call"
                                sx={{ marginInline: 1 }}
                                variant="contained"
                                color="info"
                            ></PMButton>

                            <PMButton
                                label="Hold Call"
                                sx={{ marginInline: 1 }}
                                variant="contained"
                                color="warning"
                            ></PMButton>
                        </PMGrid>
                    </PMGrid>
                </PMGrid>
            </PMCard>

            <PMGrid container={true} md={12} direction="row">
                <PMGrid container={true} md={6} sx={{ my: 1 }} direction="row">
                    <DetailWidget
                        direction="row"
                        header="POSP Summary"
                        md={12}
                        fields={[
                            { label: "POSP Name", value: page.data.renewalDetailFollowupData?.posp?.name },
                            { label: "POSP Code", value: page.data.renewalDetailFollowupData?.posp?.posp_code },
                            { label: "POSP Email", value: page.data.renewalDetailFollowupData?.posp?.posp_email },
                            { label: "RM", value: page.data.renewalDetailFollowupData?.rm?.name },
                        ]}
                    ></DetailWidget>

                    <DetailWidget
                        direction="row"
                        header="Renewal Details"
                        md={12}
                        fields={[
                            { label: "Policy No", value: page.data.renewalDetailFollowupData?.existing_policy_number },
                            { label: "Product Type", value: page.data.renewalDetailFollowupData?.product_type },
                            { label: "Coverage Type", value: page.data.renewalDetailFollowupData?.coverage_type },
                            { label: "Due Date", value: page.data.renewalDetailFollowupData?.due_date },
                            { label: "Insurer", value: page.data.renewalDetailFollowupData?.insurer?.name },
                        ]}
                    ></DetailWidget>

                    <DetailWidget
                        header="Premium Details"
                        direction="row"
                        md={12}
                        fields={[
                            {
                                label: "OD Premium",
                                value: page.data.renewalDetailFollowupData?.existing_policy?.od_net_premium || 0,
                                type: "currency",
                            },
                            {
                                label: "TP Premium",
                                value: page.data.renewalDetailFollowupData?.existing_policy?.tp_net_premium || 0,
                                type: "currency",
                            },
                            {
                                label: "Total Premium",
                                value: page.data.renewalDetailFollowupData?.existing_policy?.total_premium || 0,
                                type: "currency",
                            },
                            {
                                label: "IDV",
                                value: page.data.renewalDetailFollowupData?.existing_policy?.sum_insured || 0,
                                type: "currency",
                            },
                            {
                                label: "NCB",
                                value: (page.data.renewalDetailFollowupData?.existing_policy?.ncb || 0) + "%",
                            },
                        ]}
                    ></DetailWidget>

                    <FormWidget
                        header="Interaction with POSP"
                        Onsubmit={interactionSubmitApi}
                        fieldsets={[
                            {
                                direction: "row",
                                grid: 12,
                                fields: [
                                    {
                                        label: "Mode",
                                        name: "mode",
                                        type: "select",
                                        required: true,
                                        options: [
                                            { id: "EMAIL", label: "Email" },
                                            { id: "wHATSAPP", label: "WhatsApp" },
                                            { id: "SMS", label: "SMS" },
                                        ],
                                    },
                                    { label: "Message", name: "message", type: "textarea", required: true },
                                    { label: "Attach Document", name: "document", type: "file" },
                                ],
                            },
                        ]}
                    ></FormWidget>
                </PMGrid>

                <PMGrid container={true} md={6} sx={{ my: 1 }} direction="row">
                    <DetailWidget
                        md={12}
                        fields={[
                            {
                                label: "Script",
                                value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                            },
                        ]}
                    ></DetailWidget>

                    <TableWidget
                        header="Previous Calling History"
                        showQuickFilter={false}
                        xs={12}
                        rows={[
                            { date: "2023-01-02", status: "Renewal Due", remark: "Followup Scheduled" },
                            { date: "2023-01-04", status: "Renewal Due", remark: "Followup Scheduled" },
                        ]}
                        columns={[
                            { field: "date", headerName: "Date", type: "date" },
                            { field: "status", headerName: "Status" },
                            { field: "remark", headerName: "Remark", flex: 2 },
                        ]}
                    ></TableWidget>

                    <TableWidget
                        header="Past Interaction History"
                        showQuickFilter={false}
                        pageSize={6}
                        xs={12}
                        rows={renewalInteractionHistoryData || []}
                        columns={[
                            { field: "created_at", headerName: "Date", type: "date" },
                            { field: "type", headerName: "Activity" },
                            { field: "content", headerName: "Comment", flex: 2 },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </PMGrid>
        </div>
    );
}
export default FollowupDetail;
